import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";

import { getTrimestres } from "../../../redux/actions/trimestres-actions";
import { api_download_informes } from "../../../config";
import {
  createInformeTrimestral,
  updateInformeTrimestral,
  getInformesTrimestral,
  deleteInformeTrimestral,
} from "../../../redux/actions/informe_trimestral_actions";

import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions"; //me

class CrearRecepcionRemision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2:0
    };
  }

  componentDidMount() {
    this.cargarDatos();
    this.props.getAreasOrganizacionales();//me
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  cargarDatos = async (areafiltro) => {
    await this.props.getTrimestres();

    if(areafiltro > 0){
    await this.props.getInformesTrimestral(
      this.props.poaActivo.poaId,
      areafiltro
    );
    this.setState({areaid2:areafiltro});
  } else {
    await this.props.getInformesTrimestral(
      this.props.poaActivo.poaId,
      this.props.user.areaOrganizacional.areaOrganizacionalId
    );
  }

    this.setState({ isLoadingState: false, trimestres: this.props.trimestres });
    await this.CargarTrimestre();
  };

  cargarInformeTrimestral = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  CargarTrimestre = () => {
    let informesTrimestral = this.props.informesTrimestrales;
    let trimestreState = [...this.state.trimestres];
    for (let i = 0; i < trimestreState.length; i++) {
      for (let m = 0; m < informesTrimestral.length; m++) {
        if (
          informesTrimestral[m].trimestre.trimestreId ===
          trimestreState[i].trimestreId
        ) {
          trimestreState.splice(i, 1);
          this.setState({ trimestresEntity: trimestreState });
          i = -1;
          break;
        }
      }
    }
    if (
      this.state.trimestresEntity.length === 0 &&
      informesTrimestral.length === 0
    ) {
      this.setState({ trimestresEntity: this.state.trimestres });
    }
  };

  seleccionarTrimestre = (trimestreId) => {
    this.setState({ trimestreId: trimestreId });
  };

  enviarInforme = async (informe) => {
    this.setState({ isLoadingState: true });

    swal({
      title: "Está seguro de enviar a revisión el informe del trimestre?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        let informeTrimestral = {
          informeTrimestralId: informe.informeTrimestralId,
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          envio: true,
          comentario: "Enviado para aprobación",
        };
        await this.props.updateInformeTrimestral(informeTrimestral);
        this.setState({ isLoadingState: false });
      } else {
        this.setState({ isLoadingState: false });
      }
    });
  };

  eliminarInformeTrimestral = async (informeTrimestralId) => {

    swal({
      title: "Está seguro de querer eliminar este informe del trimestre?",
      text: "No podrá ser recuperado luego de ser eliminado",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
         this.setState({ isLoadingState: true });
         await this.props.deleteInformeTrimestral(informeTrimestralId);
         this.setState({ isLoadingState: false });
      }
    });
  };

  devolverInforme = async (informeTrimestralId, comentario) => {
    let informeTrimestral = {
      informeTrimestralId: informeTrimestralId,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      envio: true,
      comentario,
    };

    await this.props.updateInformeTrimestral(informeTrimestral);
  };

  validarEstatusEnviado = (informeTrimestral) => {
    let resultado =
      informeTrimestral.nivelAprobacion.nivelAprobacionId !==
      this.props.user.nivelAprobacion.nivelAprobacionId
        ? true
        : false;
    return resultado;
  };
  validarGuardarInforme = () => {
    if (this.state.trimestreId.length === 0) {
      swal("Informe Trimestral", "El campo trimestre es requerido para el informe del trimestre","error");

      return false;
    }

    if (this.state.file.length === 0) {
      swal("Informe Trimestral", "El campo de archivo es un valor requerido para el informe del trimestre","error");

      return false;
    }
    return true;
  };

  guardarInformeTrimestral = async () => {
    if (this.validarGuardarInforme()) {
      this.setState({ isLoadingState: true });
      this.activarModalInformeTrimestral();

      let informeTrimestral = {
        trimestreId: this.state.trimestreId,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
        nombreImagen: this.state.documento[0],
        areaOrganizacionalId: this.props.user.areaOrganizacional
          .areaOrganizacionalId,
        poaId: this.props.poaActivo.poaId,
      };

      const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }

      Object.keys(informeTrimestral).forEach(function (item) {
        fd.append(item, informeTrimestral[item]);
      });

      await this.props.createInformeTrimestral(fd);

      this.setState({ isLoadingState: false });
    }
  };

  irAtras = () => {
    this.props.history.goBack();
  }

  validar;

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">

                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-4 pb-4">
                            <h3>Detalles de la compra</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="noRequerimiento">No. Requerimiento</label>
                            <input
                            type="text"
                            className="form-control text-dark"
                            id="noRequerimiento"
                            // ref={this.actividadRef}
                            // defaultValue={descripcion}
                            // readOnly={this.state.actividadEdit}
                            required
                            disabled={true}
                            value="INT-123"
                            />

                            <div className="custom-control custom-checkbox mt-4">
                                <input type="checkbox" className="custom-control-input" id="procesoPlurianual" />
                                <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                            </div>

                            <div className="mt-4">
                                <label className="text-left" htmlFor="montoTotal">Monto Total</label>
                                <div className="input-group">
                                    <input type="text" 
                                        className="form-control" 
                                        aria-label="Amount (to the nearest dollar)" 
                                        disabled={true}
                                        value="1000"
                                        id="montoTotal"
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">$</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 mb-4">
                            <h3>Contracto</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="numeroInterno">Número interno</label>
                            <input
                                type="text"
                                className="form-control text-dark"
                                id="numeroInterno"
                                // ref={this.actividadRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                                value=""
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="vigencia">Vigencia</label>
                                <input
                                    type="date"
                                    className="form-control text-dark"
                                    id="vigencia"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>

                            <div className="mt-4">
                                <label>Documento (PDF)</label>
                                <input
                                id="inputpdf"
                                className="form-control text-dark"
                                type="file" accept=".pdf"
                                name="file[]"
                                onChange= {
                                    (e) => {
                                        e.preventDefault();
                                        ////////////////////////////////////////////////////////////
                                        var fileInput = document.getElementById('inputpdf');
                                        var filePath = fileInput.value;
                                        var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                        if (!allowedExtensions.exec(filePath)) {
                                        swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                                            fileInput.value = '';
                                            return false;
                                        } 
                                        ///////////////////////////////////////////////
                                        // props.cargarInformeTrimestral(e);
                                    }
                                }
                                />
                            </div>
                           
                        </div>
                        <div className="col-4">
                            <h3>Remisión-rece</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="fechaRemision">Fecha</label>
                            <input
                                type="date"
                                className="form-control text-dark"
                                id="fechaRemision"
                                // ref={this.actividadRef}
                                 defaultValue={new Date().toISOString().substr(0, 10)}
                                // readOnly={this.state.actividadEdit}
                                required
                                value=""
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="horaRemision">Hora</label>
                                <input
                                    type="time"
                                    className="form-control text-dark"
                                    id="horaRemision"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>

                            <h3 className="mt-5">Recepción</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="horaRecepcion">Fecha</label>
                            <input
                                type="date"
                                className="form-control text-dark"
                                id="horaRecepcion"
                                // ref={this.actividadRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                                value=""
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="horaRecepcion">Hora</label>
                                <input
                                    type="time"
                                    className="form-control text-dark"
                                    id="horaRecepcion"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>
                        </div>
                    </div>
                  </div>

                  <div className="col-md-12 mt-5">
                    <h3 className="text-center">Artículos</h3>
                    <Table>
                        <thead>
                          <tr>
                            <th>Sub-clase</th>
                            <th>Cod. Gasto</th>
                            <th>Nombre</th>
                            <th>Precio</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>D-24510</td>
                            <td>884841215120</td>
                            <td>Disco duro SSD</td>
                            <td>5000 $RD</td>
                          </tr>
                          <tr>
                            <td>D-24510</td>
                            <td>884841215120</td>
                            <td>Disco duro SSD</td>
                            <td>5000 $RD</td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.irAtras()}
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            // onClick={() => this.eliminarPoa(poaId)}
                            className="btn btn-primary"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  trimestres: state.trimestres.trimestres,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
  getTrimestres: () => dispatch(getTrimestres()),
  createInformeTrimestral: (informeTrimestral) =>
    dispatch(createInformeTrimestral(informeTrimestral)),
  getInformesTrimestral: (poaId, areaOrganizacionalId) =>
    dispatch(getInformesTrimestral(poaId, areaOrganizacionalId)),
  deleteInformeTrimestral: (informeTrimestralId) =>
    dispatch(deleteInformeTrimestral(informeTrimestralId)),
  updateInformeTrimestral: (informeTrimestral) =>
    dispatch(updateInformeTrimestral(informeTrimestral)),
    getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearRecepcionRemision, [
    "Administrador general",
    "Responsables de compras"
  ])
);

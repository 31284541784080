import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";

import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { getRequerimientos, deleteRequerimiento } from "../../redux/actions/requerimientos-actions";


import Requerimiento from "./Requerimiento.jsx";

class ListadoRequerimientos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      listaRequerimientosState: [],
      listaRequerimientosPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      listaRequerimientosState: []
    };
  }

  cargarRequerimientos = async () => {
    await this.props.getRequerimientos(this.props.user.areaOrganizacional.areaOrganizacionalId);

    this.setState({ listaRequerimientosState: this.props.requerimientos }, () =>
      this.validarPaginacion()
    );
  }

  componentDidMount() {
    this.cargarRequerimientos();
  }

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaRequerimientos = [];

    for (let i = 0; i < elementos; i++) {
      listaRequerimientos.push(this.state.listaRequerimientosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaRequerimientosState.length / elementos
    );

    this.setState({
      listaRequerimientosPageState: listaRequerimientos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  filtroRequerimiento = (requerimientoValor) => {
    let filtroRequerimiento = [];
    if (requerimientoValor.length > 0) {
      filtroRequerimiento = this.props.requerimientos.filter((requerimiento) =>
        requerimiento.numeroSolicitud.toString().includes(requerimientoValor)
      );
    } else {
      filtroRequerimiento = this.props.requerimientos;
    }

    this.setState({ listaRequerimientosState: filtroRequerimiento }, () => {
      this.validarPaginacion();
    });
  };

  eliminarRequerimiento = async (requerimientoId) => {
    // swal("Eliminar Requerimiento","Esta seguro de eliminar el requerimiento, esta acción sera definitiva","error");
    // console.log("this.props", this.props)
    let historialRequerimiento = {
      estadoId: 20,
      requerimientoId: requerimientoId,
      areaId: this.props.user.areaOrganizacional.areaOrganizacionalId,
      comentario: "El requerimiento fue eliminado por el departamento",
    }

    swal({
      title: "Eliminar Requerimiento?",
      text: "Esta seguro de eliminar el requerimiento, esta acción sera definitiva!",
      icon: "warning",
      buttons: [
        'Cancelar',
        'Aceptar'
      ],
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm) {
        this.eliminarRequerimientoDefinitivo(historialRequerimiento);
      }
    })
  }
  eliminarRequerimientoDefinitivo = async (historialRequerimiento) => {
    await this.props.deleteRequerimiento(historialRequerimiento);
    window.location.reload(false);
  }

  render() {
    const { isLoading } = this.props;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {isLoading ? (
                <Loading></Loading>
              ) : (
                <div className="row">
                  <div className="col-md-12 mt-3">

                    <div className="row">


                      <div className="form-group  col-md-6 ">
                        <label htmlFor="filtro">Filtro Requerimientos</label>
                        <input
                          type="text"
                          className="form-control form-control-sm text-dark"
                          id="filtro"
                          placeholder="Requerimientos"
                          onKeyUp={(e) => {
                            e.preventDefault();
                            this.filtroRequerimiento(e.currentTarget.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        {/* <button className="btn btn-primary" onClick={}>Crear Requerimiento</button> */}
                        <Link
                          className="btn btn-lg btn-primary float-right mb-3"
                          to="/admin/solicitud-requerimiento/index"
                        >
                          Crear Requerimiento
                        </Link>
                      </div>
                    </div>

                    <Table
                      responsive
                      className="table-striped">
                      <thead>
                        <tr>
                          <th>Número Solicitud</th>
                          <th>Estado</th>
                          <th>Área Actual</th>
                          <th>Fecha de Entrada</th>
                          <th>Tipo de Compra</th>
                          <th>Analista Asignado</th>
                          <th>Proceso Plurianual</th>
                          <th>SIGEF</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listaRequerimientosPageState.map(
                          (requerimiento) =>
                            requerimiento != null &&
                            requerimiento != undefined && (
                              <Requerimiento
                                key={requerimiento.requerimientoId}
                                requerimiento={requerimiento}
                                user={this.props.user}
                                eliminarRequerimiento={this.eliminarRequerimiento}
                              />
                            )
                        )}
                      </tbody>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          prevPageText="ant"
                          nextPageText="sig"
                          firstPageText="prime"
                          lastPageText="ultimo"
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemPerPage}
                          totalItemsCount={this.state.listaRequerimientosState.length}
                          onChange={this.validarPaginacion.bind(this)}
                        />
                      </div>
                    </Table>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.signed,
  isLoading: state.requerimientos.isLoading,
  requerimientos: state.requerimientos.requerimientos
});

const mapDispatchToProps = (dispatch) => ({
  getRequerimientos: (areaId) => dispatch(getRequerimientos(areaId)),
  deleteRequerimiento: (historialRequerimiento) => dispatch(deleteRequerimiento(historialRequerimiento))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListadoRequerimientos, [
    "Administrador general",
    "Responsables de compras"
  ])
);

//Servidor MEM - Development
// http://dev.mem.gob.do/restapi/

//Servidor Local
// const api_url = "http://localhost:8000/index.php/";
const api_url = "https://sispoa.mem.gob.do/restapi/";
// const api_url = "http://sispoa-dev.mem.local/restapi/";

export const api_base = `${api_url}`;
export const api_download = `${api_url}/uploads/MediosVerificacion/`;
export const api_download_informes = `${api_url}/uploads/InformesTrimestrales/`;
export const api_download_reportes = `${api_url}sispoa-api/public/reportes/`;
export const api_download_especificaciones_tecnicas = `${api_url}sispoa-api/public/uploads/especificaciones_tecnicas/`;

export const client = {
  id: "d8c772d8-d571-11e9-8840-484d7ed5ec94",
  secret: "123",
};

export const endpoints = {
  login: "api/v1/oauth/token",
  searchUser: api_base + "api/v1/usuarios/search/",
};

export const homePages = {
  productos: "/admin/productos/index",
  admin: "admin/index",
};

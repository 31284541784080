import {
  MOSTRAR_INSUMOS,
  LOADING_INSUMOS,
  INSUMOS_SUCCEED,
  INSUMOS_FAILED,
  MOSTRAR_OPERACIONES,
  LOADING_OPERACIONES,
  OPERACIONES_SUCCEED,
  OPERACIONES_FAILED,
  PRESUPUESTO_FAILED,
  PRESUPUESTO_SUCCEEDED,
  ELIMINAR_PRESUPUESTO,
  AGREGAR_INSUMOS_TEMPORALES,
  LIMPIAR_INSUMOS_TEMPORALES
} from "./../types";

import InitialState from "./../initial-state";

const { maestroInsumos } = InitialState;

const MaestroInsumosReducer = (state = maestroInsumos, action) => {
  switch (action.type) {
    case MOSTRAR_INSUMOS:
      return {
        ...state,
        maestroInsumos: action.payload
      };
    case MOSTRAR_OPERACIONES:
      return {
        ...state,
        operaciones: action.payload
      };
    case AGREGAR_INSUMOS_TEMPORALES:
      return{
        ...state,
         insumosTemporales:action.payload
      };
    case LIMPIAR_INSUMOS_TEMPORALES:
      return{
        ...state,
        insumosTemporales:[]
      };
    case ELIMINAR_PRESUPUESTO:
      return {
        ...state,
        presupuesto: [
          ...state.presupuesto.filter(
            insumo => insumo.presupuestoId !== action.payload
          )
        ]
      };
    case LOADING_INSUMOS:
    case LOADING_OPERACIONES:
      return {
        ...state,
        isLoading: true
      };
    case INSUMOS_SUCCEED:
    case INSUMOS_FAILED:
    case OPERACIONES_SUCCEED:
    case PRESUPUESTO_SUCCEEDED:
    case OPERACIONES_FAILED:
    case PRESUPUESTO_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default MaestroInsumosReducer;

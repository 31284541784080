import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";
import numbro from "numbro";

import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { getAreasOrganizacionales } from "../../redux/actions/areas-actions"; 
import { getRequerimiento, asignarBienServicioRequerimiento } from "../../redux/actions/requerimientos-actions";
import { getListaUsuarios, getListaAnalistasAsignados } from "../../redux/actions/usuarios-actions";
import { habilitarProcesoPlurianual } from "../../utils/habilitarCampos.js"

class EditarExpedientesFisicos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      areaid2:0,
      anlistaId: 0,
      tipoDocumento: 0,
      file: "",
      documento: "",
    };
  }

  // Ref
  procesoPlurianualRef = React.createRef();
  montoTotalAdjudicadoRef = React.createRef();
  tipoDocumentoRef = React.createRef();
  numeroOrdenRef = React.createRef();
  fechaOCRef = React.createRef();
  fechaRemisionRef = React.createRef();
  horaRemisionRef = React.createRef();

  componentDidMount() {
    this.cargarDatos();
    this.props.getAreasOrganizacionales();
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  cargarDatos = async () => {
    await this.props.getRequerimiento(this.props.match.params.solicitudCompraId);
    await this.props.getListaAnalistasAsignados();
    this.setState({ isLoadingState: false });
  }

  cargarBienServicio = (bienServicio) => {
    let files = [];
    let documentos = [];
    if (bienServicio.target.files.length > 0) {
      for (let i = 0; i < bienServicio.target.files.length; i++) {
        files.push(bienServicio.target.files[i]);
        documentos.push(bienServicio.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  guardar = async () => {
    this.setState({ isLoadingState: true });

    let expedienteFisico = {
      requerimientoId: this.props.match.params.solicitudCompraId,
      procesoPlurianual: document.getElementById("procesoPlurianual").checked,
      tipoDocumento: this.state.tipoDocumento,
      involucradoId: this.state.analistaId,
      montoTotalAdjudicado: this.montoTotalAdjudicadoRef.current.value || null,
      numeroOrden: this.numeroOrdenRef.current.value,
      fechaOC: this.fechaOCRef.current ? this.fechaOCRef.current.value : "",
      fechaRemision: this.fechaRemisionRef.current ? this.fechaRemisionRef.current.value : "",
      horaRemision: this.horaRemisionRef.current ? this.horaRemisionRef.current.value : "",
    }

    if(this.validar(expedienteFisico)) {
      const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }
  
      Object.keys(expedienteFisico).forEach(function (item) {
        fd.append(item, expedienteFisico[item]);
      });
  
      await this.props.asignarBienServicioRequerimiento(fd);
  
      setTimeout(() => {
        window.location.reload();
      }, "1500");
  
      swal("Bienes y servicios", "Ha sido guardado correctamente","success");
    }

    this.setState({ isLoadingState: false });
  }

  validar = (expedienteFisico) => {
    const {
      requerimientoId,
      procesoPlurianual,
      tipoDocumento,
      involucradoId,
      montoTotalAdjudicado,
      numeroOrden,
      fechaOC,
      fechaRemision,
      horaRemision
    } = expedienteFisico;
  
    if (!requerimientoId) {
      swal("Crear bienes - servicios", "El ID de requerimiento es necesario", "error");
      return false;
    }
  
    if (!tipoDocumento) {
      swal("Crear bienes - servicios", "El tipo de documento es requerido", "error");
      return false;
    }
  
    if (!involucradoId) {
      swal("Crear bienes - servicios", "El ID del involucrado es requerido", "error");
      return false;
    }
  
    if (procesoPlurianual) {
      if(montoTotalAdjudicado === "" || montoTotalAdjudicado === null) {
        swal("Crear bienes - servicios", "El monto total adjudicado es requerido", "error");
      }
      return false;
    }
  
    if (!numeroOrden) {
      swal("Crear bienes - servicios", "El número de orden es necesario", "error");
      return false;
    }
  
    if (!fechaOC) {
      swal("Crear bienes - servicios", "La fecha de la orden de compra es requerida", "error");
      return false;
    }
  
    if (!fechaRemision) {
      swal("Crear bienes - servicios", "La fecha de remisión es requerida", "error");
      return false;
    }
  
    if (!horaRemision) {
      swal("Crear bienes - servicios", "La hora de remisión es requerida", "error");
      return false;
    }
  
    return true;
  };

  render() {
    const { analista } = this.props.requerimiento;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">

                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-6 pb-4 border-right">
                            <label className="text-left" htmlFor="noRequerimiento">No. Requerimiento</label>
                            <input
                            type="text"
                            className="form-control text-dark"
                            id="noRequerimiento"
                            required
                            disabled={true}
                            value={this.props.requerimiento.numeroSolicitud}
                            />

                          <div className="form-group mt-4">
                            <label htmlFor="tipoDocumento">Tipo de documento</label>
                              <select
                                id="tipoDocumento"
                                className="form-control"
                                ref={this.tipoDocumentoRef}
                                onChange={(e) => {
                                  this.setState({ tipoDocumento: e.currentTarget.value })
                                }}
                              >
                                <option value="0" selected="">--Seleccionar tipos de compra--</option>
                                <option value={1}>Orden de Compra</option>
                                <option value={2}>Orden de Compra 2</option>
                              </select>
                          </div>

                            <label className="text-left" htmlFor="numeroOrden">Número de Orden</label>
                            <input
                                type="text"
                                className="form-control text-dark"
                                id="numeroOrden"
                                ref={this.numeroOrdenRef}
                                // defaultValue={descripcion}
                                // readOnly={this.state.actividadEdit}
                                required
                            />

                            <div className="mt-4">
                                <label className="text-left" htmlFor="fechaOC">Fecha de O/C</label>
                                <input
                                    type="date"
                                    className="form-control text-dark"
                                    id="fechaOC"
                                    ref={this.fechaOCRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                />
                            </div>

                            <div className="form-group mt-4">
                            <label htmlFor="involucrados">Involucrados</label>
                              <select
                                id="involucrados"
                                className="form-control"
                                ref={this.involucradosRef}
                                // value={analista && analista.id > 0 ? { value: analista.id, label: analista.name } : null}
                                defaultValue={analista ? analista.id : 0}
                                onChange={(e) => {
                                  this.setState({ analistaId: e.currentTarget.value })
                                }}
                              >
                                <option
                                  key={0}
                                  value={0}
                                >--Seleccionar destinatarios--</option>
                                {this.props.usuariosAnalistasAsignados && (this.props.usuariosAnalistasAsignados.map((analista) => (
                                  <option
                                    key={analista.id}
                                    value={analista.id}
                                  >
                                    {analista.name}
                                  </option>
                                )))}
                              </select>
                          </div>
                     
                        </div>
                        <div className="col-6 mb-4 border-right">
                            <div className="custom-control custom-checkbox mt-4">
                                <input type="checkbox" className="custom-control-input" id="procesoPlurianual" ref="procesoPlurianualRef" onChange={ () => habilitarProcesoPlurianual() }/>
                                <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                            </div>

                            <div className="mt-4 procesoPluriannual aplicaProcesoPlurianual">
                                <label className="text-left" htmlFor="montoTotal">Monto total adjudicado</label>
                                <div className="input-group">
                                    <input type="number" 
                                        id="montoTotal"
                                        className="form-control" 
                                        aria-label="Amount (to the nearest dollar)" 
                                        placeholder="1000"
                                        ref={this.montoTotalAdjudicadoRef}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">$</span>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <label>PDF de la orden</label>
                                <input
                                id="inputpdf"
                                className="form-control text-dark"
                                type="file" accept=".pdf"
                                name="file[]"
                                onChange= {
                                    (e) => {
                                        e.preventDefault();
                                        ////////////////////////////////////////////////////////////
                                        var fileInput = document.getElementById('inputpdf');
                                        var filePath = fileInput.value;
                                        var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                        if (!allowedExtensions.exec(filePath)) {
                                        swal("Error de tipo de archivo","Debe cargar archivos en formato PDF","error");
                                            fileInput.value = '';
                                            return false;
                                        } 
                                        ///////////////////////////////////////////////
                                        this.cargarBienServicio(e);
                                    }
                                }
                                />
                            </div>

                            <div className="mt-4">
                              <h3>Remisión</h3>
                              <hr style={{ marginTop: '0rem' }}/>
                              <label className="text-left" htmlFor="fechaRemision">Fecha</label>
                              <input
                                  type="date"
                                  className="form-control text-dark"
                                  id="fechaRemision"
                                  ref={this.fechaRemisionRef}
                                  // defaultValue={descripcion}
                                  // readOnly={this.state.actividadEdit}
                                  required
                              />

                              <div className="mt-4">
                                  <label className="text-left" htmlFor="horaRemision">Hora</label>
                                  <input
                                      type="time"
                                      className="form-control text-dark"
                                      id="horaRemision"
                                      ref={this.horaRemisionRef}
                                      // defaultValue={descripcion}
                                      // readOnly={this.state.actividadEdit}
                                      required
                                  />
                              </div>
                            </div>
                           
                        </div>
                    </div>
                  </div>
                 
                  <div className="col-md-12 mt-5">
                      <div className="row pb-3 pt-3">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.props.history.goBack() }
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            onClick={() => this.guardar() }
                            className="btn btn-primary"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  requerimiento: state.requerimientos.requerimiento,
  usuariosAnalistasAsignados: state.usuarios.usuariosAnalistasAsignados,
});

const mapDispatchToProps = (dispatch) => ({
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  getRequerimiento: (requerimientoId) => dispatch(getRequerimiento(requerimientoId)),
  getListaAnalistasAsignados: () => dispatch(getListaAnalistasAsignados()),
  asignarBienServicioRequerimiento: (requerimiento) => dispatch(asignarBienServicioRequerimiento(requerimiento))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarExpedientesFisicos, [
    "Administrador general",
    "Responsables de compras"
  ])
);

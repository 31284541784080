import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { deleteRequerimiento } from "../../redux/actions/requerimientos-actions.js";
import Moment from "react-moment";
import numbro from "numbro";
class Insumos extends Component {
  state = {

  };
  eliminarInsumosRequerimiento = () => {
    let confirmed = window.confirm("Está seguro de eliminar el requerimiento");
    if (confirmed) {
      //   this.props.deleteRequerimiento(this.props.requerimiento);
    }
  };



  render() {
    // console.log("ActividadArticuloSeguimiento", this.props.ActividadArticuloSeguimiento)
    const { articuloId, nombreArticulo, presupuesto, estatus, analista, tipoCompras, sigef } = this.props.ActividadArticuloSeguimiento;
    const { subclase, cuenta, totalCosto } = presupuesto;
    return (
      estatus && (<tr >
        <td className="text-left">{nombreArticulo}</td>
        <td>{subclase}</td>
        <td>
          {cuenta}
        </td>
        {/* <td>{totalCosto}</td> */}
        <td>{'RD$ '+numbro(totalCosto).format({thousandSeparated:true})}</td>
        <td >

          <i className="fas fa-eye text-primary" style={{ cursor: "pointer" }} onClick={() => this.props.ActivarModalDetalleArticulo(presupuesto)}></i>
          {/*            
       {this.props.editar&&(
       this.props.user.nivelAprobacion.nivelAprobacionId < 3 ? (
         <button
           className="btn btn-success btn-sm"
           disabled={true}
           title="No tiene permisos para editar los requerimientos"
         >
           Editar
         </button>
       ) : (
         <Link
         to={{
           pathname: `editar/${articuloId}`,
           state: "/admin/solicitud-requerimiento/editar/:requerimientoId",
         }}
         className="p-2"
       >
         <i className="fas fa-pen text-primary"></i>
       </Link>
       )
       )} */}
          {this.props.editar && (
            (this.props.user.nivelAprobacion.nivelAprobacionId > 5 ? (
              <button
                className="btn btn-success btn-sm"
                disabled={true}
                title="No tiene permisos para editar los requerimientos"
              >
                <i className="fas fa-times text-primary"></i>
              </button>
            ) : (

              <i className="fas fa-times text-primary " style={{ cursor: "pointer", marginLeft: 5 }} onClick={() => {
                this.props.eliminarPresupuesto(articuloId);
              }}></i>

            ))
          )}
        </td>
      </tr>

      ));

  }

}

export default connect(null, {})(Insumos);

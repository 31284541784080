import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";
import { Link } from "react-router-dom";

import { ModalRecepcionBienesServicios } from "../../utils/ModalRecepcionBienesServicios";
import Requerimiento from "../../requerimientos/Requerimiento";

import { api_download_informes } from "../../../config";
import Pagination from "react-js-pagination";


import { getAreasOrganizacionales } from "../../../redux/actions/areas-actions"; //me
import { getRequerimientosAdmin } from "./../../../redux/actions/requerimientos-actions";

class ListaDepartamentoAdministrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,

      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2: 0,
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
      listaRequerimientosPageState: []
    };
  }

  componentDidMount() {
    // this.props.getAreasOrganizacionales();//me
    this.cargarDatos();
    this.setState({ areaid2: this.props.user.areaOrganizacional.areaOrganizacionalId })
  }
  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaRequerimientos = [];

    for (let i = 0; i < elementos; i++) {
      listaRequerimientos.push(this.state.listaRequerimientosState[index]);

      index++;
    }

    let totalPages = Math.ceil(
      this.state.listaRequerimientosState.length / elementos
    );

    this.setState({
      listaRequerimientosPageState: listaRequerimientos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  cargarDatos = async () => {
    await this.props.getAreasOrganizacionales();
    this.setState({ areaid2: this.props.user.areaOrganizacional.areaOrganizacionalId })
    await this.props.getRequerimientosAdmin(3);
    // console.log("this.props.requerimientosAdmin",this.props.requerimientosAdmin);
    this.setState({ listaRequerimientosState: this.props.requerimientosAdmin, isLoadingState: false })
    this.validarPaginacion();
  }





  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <div className="form-group  col-md-6 ">
                      <label htmlFor="filtro">Filtro Requerimientos</label>
                      <input
                        type="text"
                        className="form-control form-control-sm text-dark"
                        id="filtro"
                        placeholder="Requerimientos"
                        onKeyUp={(e) => {
                          e.preventDefault();
                          this.filtroRequerimiento(e.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>

                  <Table
                    responsive
                    className="table-striped">
                    <thead>
                      <tr>
                        <th>Número Solicitud</th>
                        <th>Estado</th>
                        <th>Área Actual</th>
                        <th>Fecha de Entrada</th>
                        <th>Tipo de Compra</th>
                        <th>Analista Asignado</th>
                        <th>Proceso Plurianual</th>
                        <th>SIGEF</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.listaRequerimientosPageState.map(
                        (requerimiento) =>
                          requerimiento != null &&
                          requerimiento != undefined && (
                            <Requerimiento
                              key={requerimiento.requerimientoId}
                              requerimiento={requerimiento}
                              user={this.props.user}
                              eliminarRequerimiento={this.eliminarRequerimiento}
                            />
                          )
                      )}
                    </tbody>
                    <div className="d-flex justify-content-center">
                      <Pagination
                        prevPageText="ant"
                        nextPageText="sig"
                        firstPageText="prime"
                        lastPageText="ultimo"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemPerPage}
                        totalItemsCount={this.state.listaRequerimientosState.length}
                        onChange={this.validarPaginacion.bind(this)}
                      />
                    </div>
                  </Table>
                </div>
              </div>
                // <div className="row">
                //   <div className="col-md-12 mt-3">
                //     {/* //me */}
                //     <div className="col-md-5 text-left" style={{ display: "none" }}>
                //       <label>Filtro Área</label>
                //       <select
                //         className="form-control"
                //         defaultValue={this.state.areaid2}

                //         id="areaSelected"
                //         onChange={(e) => {
                //           e.preventDefault();

                //           this.setState({ isLoadingState: true });
                //           this.setState({ areaid2: document.getElementById("areaSelected").value });
                //           this.cargarDatos(document.getElementById("areaSelected").value);
                //         }}
                //       >
                //         {this.props.areaOrganizacionales.map((area) => (
                //           <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                //             {area.nombre}
                //           </option>

                //         ))}
                //       </select>

                //       <label>Filtro Área</label>
                //       <select
                //         className="form-control"
                //         defaultValue={this.state.areaid2}

                //         id="areaSelected"
                //         onChange={(e) => {
                //           e.preventDefault();

                //           this.setState({ isLoadingState: true });
                //           this.setState({ areaid2: document.getElementById("areaSelected").value });
                //           this.cargarDatos(document.getElementById("areaSelected").value);
                //         }}
                //       >
                //         {this.props.areaOrganizacionales.map((area) => (
                //           <option key={area.areaOrganizacionalId} value={area.areaOrganizacionalId}>
                //             {area.nombre}
                //           </option>

                //         ))}
                //       </select>

                //     </div>

                //     <div className="col-md-12 text-left">
                //       {/* <h3>APROBAR SOLICITUDES DE COMPRAS DEPARTAMENTO ADMINISTRATIVO</h3> */}
                //     </div>
                //     <div className="col-md-12 mt-3">
                //       {this.state.listaRequerimientosState && this.state.listaRequerimientosState.length > 0 ? (
                //         <Table
                //           responsive
                //           className="table-striped">
                //           <thead>
                //             <tr>
                //               <th>Número Solicitud</th>
                //               <th>Estado</th>
                //               <th>Área Actual</th>
                //               <th>Fecha de Entrada</th>
                //               <th>Tipo de Compra</th>
                //               <th>Analista Asignado</th>
                //               <th>Proceso Plurianual</th>
                //               <th>SIGEF</th>
                //               <th>Acciones</th>
                //             </tr>
                //           </thead>
                //           <tbody>
                //             {this.state.listaRequerimientosPageState.map(
                //               (requerimiento) =>
                //                 requerimiento != null &&
                //                 requerimiento != undefined && (
                //                   <Requerimiento
                //                     key={requerimiento.requerimientoId}
                //                     requerimiento={requerimiento}
                //                     user={this.props.user}
                //                   // eliminarRequerimiento={this.eliminarRequerimiento}
                //                   />
                //                 )
                //             )}
                //           </tbody>
                //           <div className="d-flex justify-content-center">
                //             <Pagination
                //               prevPageText="ant"
                //               nextPageText="sig"
                //               firstPageText="prime"
                //               lastPageText="ultimo"
                //               itemClass="page-item"
                //               linkClass="page-link"
                //               activePage={this.state.activePage}
                //               itemsCountPerPage={this.state.itemPerPage}
                //               totalItemsCount={this.state.listaRequerimientosState.length}
                //               onChange={this.validarPaginacion.bind(this)}
                //             />
                //           </div>
                //         </Table>
                //       ) : (<h3>No tiene solicitudes de compras pendientes por aprobar</h3>)}
                //     </div>
                //   </div>
                // </div>
              )}
            </div>
            <ModalRecepcionBienesServicios
              guardarInformeTrimestral={this.guardarInformeTrimestral}
              trimestres={this.state.trimestresEntity}
              seleccionarTrimestre={this.seleccionarTrimestre}
              modalInformeTrimestralStatus={
                this.state.modalInformeTrimestralStatus
              }
              cargarInformeTrimestral={this.cargarInformeTrimestral}
              activarModalInformeTrimestral={this.activarModalInformeTrimestral}
            ></ModalRecepcionBienesServicios>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  requerimientosAdmin: state.requerimientos.requerimientosAdmin,
  informesTrimestrales: state.informesTrimestrales.informesTrimestrales,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,//me
});

const mapDispatchToProps = (dispatch) => ({
  getRequerimientosAdmin: (estadoId) => dispatch(getRequerimientosAdmin(estadoId)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),//me
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ListaDepartamentoAdministrativo, [
    "Administrador general",
    "Responsables de compras"
  ])
);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
} from "reactstrap";

import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { ModalInformeTrimestral } from "../utils/ModalInformeTrimestral";
import { getAreasOrganizacionales } from "../../redux/actions/areas-actions"; //me
import { getProductosActividadInsumos } from "../../redux/actions/productos-actions";
import { createRequerimientos, getRequerimientos } from "../../redux/actions/requerimientos-actions";

import {
  UncontrolledCollapse,
  Button,
  CardBody,
  Card,
  Row,
} from 'reactstrap';
import expedientesFisicos from "../expedientes-fisicos";
import { ModalGuardarRequerimiento } from "./ModalGuardarRequerimiento";
import swal from "sweetalert";

class SolicitudRequerimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaOrganizacionalId: 0,
      productosActividadInsumos: [],
      productosActividadInsumosSelected: [],
      actividadesSelected: [],
      insumosActividadesSelected: [],
      productosActividadesInsumosFiltered: [],
      modalGuardarRequerimiento: false
    };
  }

  componentDidMount() {
    this.setState({ areaOrganizacionalId: this.props.user.areaOrganizacional.areaOrganizacionalId })
    this.cargarDatos();
    this.props.getAreasOrganizacionales();
    this.props.getRequerimientos(this.props.user.areaOrganizacional.areaOrganizacionalId);

  }

  cargarDatos = async () => {
    const { poaId } = this.props.poaActivo;
    const { areaOrganizacionalId } = this.props.user.areaOrganizacional;
    await this.props.getProductosActividadInsumos(poaId, areaOrganizacionalId);
    // console.log(this.props.productosActividadInsumos);
    this.setState({ isLoadingState: false, productosActividadInsumos: this.props.productosActividadInsumos });
    this.validarProductosMostrar(this.props.productosActividadInsumos)
  };

  validarProductosMostrar = (productosActividadInsumos) => {
    let productosActividadesInsumosFiltered = [];

    productosActividadInsumos.forEach(producto => {
      let arrayActividades = [];
      producto.actividades.forEach(actividad => {
        let arrayPresupuesto = [];
        if (actividad.actividadesArticulosSeguimientos && actividad.actividadesArticulosSeguimientos.length == 0 && actividad.presupuesto.length>0) {

          let actividadNew = {
            actividadId: actividad.actividadId,
            descripcion: actividad.descripcion,
            presupuesto: actividad.presupuesto
          }
          arrayActividades = [...arrayActividades, actividadNew]
        } else {
          if(actividad.actividadesArticulosSeguimientos || undefined !== actividad.actividadesArticulosSeguimientos) {
            //console.log('actividad', actividad.actividadesArticulosSeguimientos)
            actividad.presupuesto.forEach(presupuesto => {
              let presupuestoEncontrado = actividad.actividadesArticulosSeguimientos.find(x => x.articuloId === presupuesto.presupuestoId);
              if (presupuestoEncontrado && !presupuestoEncontrado.estatus) {
                arrayPresupuesto = [...arrayPresupuesto, presupuesto]
              }else if(presupuestoEncontrado==undefined){
                arrayPresupuesto = [...arrayPresupuesto, presupuesto]
              }
            });
          } else {
            arrayPresupuesto = [];
          }
          if (arrayPresupuesto.length > 0) {

            let actividadNew = {
              actividadId: actividad.actividadId,
              descripcion: actividad.descripcion,
              presupuesto: arrayPresupuesto
            }
            arrayActividades = [...arrayActividades, actividadNew]
          }
        }

      })
      if (arrayActividades.length > 0) {
        let productoArray = {
          productoId: producto.productoId,
          descripcion: producto.descripcion,
          actividades: arrayActividades
        }
        productosActividadesInsumosFiltered = [...productosActividadesInsumosFiltered, productoArray];
      }
    });
    this.setState({ productosActividadesInsumosFiltered: productosActividadesInsumosFiltered })
  }

  validarCheckFromActividades = (actividadSelected, insumos, producto) => {

    const { checked, value } = actividadSelected;
    if (checked) {
      this.setState({ actividadesSelected: [...this.state.actividadesSelected, parseInt(value)], productosActividadInsumosSelected: [...this.state.productosActividadInsumosSelected, producto] });
    } else {
      let actividadSelectedFilter = this.state.actividadesSelected.filter(x => x != value);
      let productoActividadInsumoSelectedFilter = this.state.productosActividadInsumosSelected.filter(x => x != producto);
      this.setState({ actividadesSelected: actividadSelectedFilter, productosActividadInsumosSelected: productoActividadInsumoSelectedFilter })
    }

    this.validarCheckFromInsumosAll(checked, insumos)
  }

  validarCheckFromInsumosAll = (checked, insumos) => {
    if (checked) {
      let insumosAll = this.state.insumosActividadesSelected;
      insumos.map(x => insumosAll = [...insumosAll, x.presupuestoId]);
      this.setState({ insumosActividadesSelected: insumosAll })
    } else {
      let insumosAll = this.state.insumosActividadesSelected;
      insumos.forEach(element => {
        insumosAll = insumosAll.filter(x => x != element.presupuestoId);
      });
      this.setState({ insumosActividadesSelected: insumosAll })
    }
  }

  validarCheckFromInsumos = (insumoSelected, producto) => {
    const { checked, value } = insumoSelected;
    if (checked) {
      this.setState({ insumosActividadesSelected: [...this.state.insumosActividadesSelected, parseInt(value)], productosActividadInsumosSelected: [...this.state.productosActividadInsumosSelected, producto] });
    } else {
      let insumosFilter = this.state.insumosActividadesSelected.filter(x => x !== parseInt(value));
      let productosFilter = this.state.productosActividadInsumosSelected.filter(x => x !== producto)
      this.setState({ insumosActividadesSelected: insumosFilter, productosActividadInsumosSelected: productosFilter })
    }
  }

  guardarRequerimiento = async (requerimientoProps) => {
    this.setState({isLoadingState:true,modalGuardarRequerimiento:!this.state.modalGuardarRequerimiento});
    // obtener valores
    var insumos = this.state.insumosActividadesSelected;
    var actividades = this.state.actividadesSelected;
    var producto = this.state.productosActividadInsumosSelected;

    // console.log("insumos", insumos);
    // console.log("actividades", actividades)
    // console.log("producto", producto)

    let requerimiento = {
      insumos,
      estadoId: 2,
      nombreDocumento:this.state.documento[0],
      numeroTransdoc:requerimientoProps.numeroTransdoc,
      justificacion:requerimientoProps.justificacion
    }
    const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }

      Object.keys(requerimiento).forEach(function (item) {
        fd.append(item, requerimiento[item]);
      });

    // hacer llamado api para guardar el requerimiento 
    await this.props.createRequerimientos(fd);

      swal("Exito!",
      "El requerimiento fue creado",
      "success"
      );

      setTimeout(() => {
        window.location.reload();
      }, 1500);
  }

  cargarEspecificacionesTecnicas = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  activarModalGuardarRequerimiento = () => {
    if (this.state.insumosActividadesSelected.length > 0) {
      this.setState({ modalGuardarRequerimiento: !this.state.modalGuardarRequerimiento });
    } else {
      this.setState({modalGuardarRequerimiento:false});
      swal(
        "Advertencia",
        "Debe agregar los insumos que desea incluir en el requerimiento",
        "error"
      );
    }

  }



  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">
                  <div className="col-md-12 text-right">
                    <div className="row">
                      <div className="col-6"> <h1 style={{ textAlign: 'left' }}>Listado de Productos</h1></div>
                      <div className="col-6">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.activarModalGuardarRequerimiento()}
                        >
                          Crear Requerimiento
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="col-md-12 mt-3">
                    <h3>Producto</h3>
                    {this.props.productosActividadInsumos.length > 0 && this.state.productosActividadesInsumosFiltered.length>0 ? (
                      this.state.productosActividadesInsumosFiltered.map(x => (
                        <div>
                          <Button
                            block
                            color="default"
                            outline type="button"
                            id={`u${x.productoId}`}
                            size="lg"
                            style={{
                              marginBottom: '1rem'
                            }}
                            className="text-left"
                          >
                            {x.descripcion}
                          </Button>
                          {x.actividades.map((a) => (
                            <UncontrolledCollapse toggler={`#u${x.productoId}`}>
                              <Card className="mb-3">
                                <CardBody>
                                  <div className="custom-control custom-checkbox mb-3">
                                    <input
                                      className="custom-control-input"
                                      id={a.actividadId}
                                      type="checkbox"
                                      value={a.actividadId}
                                      onChange={c => this.validarCheckFromActividades(c.currentTarget, a.presupuesto, x.productoId)}
                                    />
                                    <label className="custom-control-label" htmlFor={a.actividadId}>
                                      {a.descripcion}
                                    </label>
                                  </div>
                                  <div className="px-3">
                                    {a.presupuesto.map(p => (
                                      <div className="custom-control custom-checkbox mb-2" key={p.presupuestoId}>
                                        <Row>
                                          <div className="col">
                                            <input
                                              className="custom-control-input"
                                              id={p.presupuestoId}
                                              value={p.presupuestoId}
                                              onChange={pc => this.validarCheckFromInsumos(pc.currentTarget, x.productoId)}
                                              type="checkbox"
                                              checked={this.state.insumosActividadesSelected.length > 0 ? this.state.insumosActividadesSelected.includes(p.presupuestoId) : false}
                                            />

                                            <label className="custom-control-label" htmlFor={p.presupuestoId}>
                                              {p.referenciaInsumo ? p.insumo : p.insumo}
                                            </label>
                                          </div>

                                          <div className="col-auto">
                                            <div className="form-check form-check-inline">
                                              <label className="form-check-label mr-2 small" htmlFor="inlineCheckbox1">Cantidad</label>

                                              <input
                                                type="number"
                                                className="form-control form-control-sm text-dark"
                                                id="actividad"
                                                placeholder="Digite cantidad"
                                                // ref={this.actividadRef}
                                                defaultValue={p.cantidad}
                                                //  onChange={()=>{validatetotal}}
                                                readOnly
                                                required
                                              />
                                            </div>
                                          </div>
                                        </Row>
                                      </div>
                                    ))}

                                  </div>

                                </CardBody>
                              </Card>
                            </UncontrolledCollapse>
                          ))}

                        </div>
                      ))

                    ) : (
                      <label>No existen productos con insumos agregados para el área organizacional <strong>{this.props.user.areaOrganizacional.nombre}</strong></label>
                    )}

                  </div>
                </div>
              )}
            </div>
            {
              this.state.modalGuardarRequerimiento && (
                <ModalGuardarRequerimiento
                  guardarRequerimiento={this.guardarRequerimiento}
                  cargarEspecificacionesTecnicas={this.cargarEspecificacionesTecnicas}
                  activarModalGuardarRequerimiento={this.activarModalGuardarRequerimiento}
                  modalGuardarRequerimiento={this.state.modalGuardarRequerimiento}
                ></ModalGuardarRequerimiento>
              )
            }

          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  areaOrganizacionales: state.areasOrganizacionales.areasOrganizacionales,
  productosActividadInsumos: state.productos.productosActividadInsumos,
  requerimientos: state.requerimientos.requerimientos
});

const mapDispatchToProps = (dispatch) => ({
  getProductosActividadInsumos: (poaId, area) => dispatch(getProductosActividadInsumos(poaId, area)),
  getAreasOrganizacionales: () => dispatch(getAreasOrganizacionales()),
  createRequerimientos: (requerimiento) => dispatch(createRequerimientos(requerimiento)),
  getRequerimientos: (areaOrganizacionalId) => dispatch(getRequerimientos(areaOrganizacionalId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(SolicitudRequerimiento, [
    "Administrador general",
    "Responsables de compras"
  ])
);

import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";

import { Authorization } from "../../../utils/Authorization";
import Loading from "../../utils/Loading";

import { ModalAsignacionItemsProveedor } from "../../utils/ModalAsignacionItemsProveedor";
import { ModalEditarAsignacionItemsProveedor } from "../../utils/ModalEditarAsignacionItemsProveedor";
import { getProveedores, createProveedorPorRequerimientoCompra, getProveedoresInsumosAdjudicados, getProveedorPorRequerimientoCompra, deleteProveedorRequerimientoCompra } from "../../../redux/actions/proveedores-actions";
import { deleteActividadesArticuloSeguimientoAdjudicado, getActividadesArticuloSeguimientoPorProveedor, getCantidadTotalItemsProveedor, setActividadesArticuloSeguimientoAdjudicado } from "../../../redux/actions/actividades-actions";

import { getRequerimiento, getRequerimientoCompraByRequerimiento } from "../../../redux/actions/requerimientos-actions";

class CrearProcesoCompras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      modalAsignacionItemsProveedorStatus: false,
      modalEditarAsignacionItemsProveedorStatus: false,
      editarProveedor: {},
      selectProveedoresOptions: 0,
      selectArticulosOptions: [],
      proveedorSeleccionado: 0,
      desabilitarboton: false,
      articulosParaBorrar: [],
      articulos: [],
      totalItems: 0
    };
  }

  // Ref - detalles
  requerimientoIdRef = React.createRef();
  noRequerimientoRef = React.createRef();
  fechaLanzamientoRef = React.createRef();
  numeroProcesoCompra = React.createRef();
  fechaRemisionRef = React.createRef();
  horaRemisionRef = React.createRef();
  documentacionIdRef = React.createRef();

  // Ref - Proveedores
  nombreProveedorRef = React.createRef();
  cantidadItemsRef = React.createRef();
  proveedoresRef = React.createRef();
  precioAdjudicacionRef = React.createRef();

  componentDidMount() {
    this.cargarDatos();
  }

  cargarDatos = async () => {
    await this.props.getRequerimiento(this.props.match.params.solicitudCompraId);
    await this.props.getRequerimientoCompraByRequerimiento(this.props.match.params.solicitudCompraId);
    await this.props.getProveedores();
    await this.props.getActividadesArticuloSeguimientoPorProveedor(this.props.match.params.solicitudCompraId);
    await this.props.getCantidadTotalItemsProveedor(this.props.match.params.solicitudCompraId);
    await this.props.getProveedoresInsumosAdjudicados();
    await this.props.getProveedorPorRequerimientoCompra();

    this.actualizarCantidadItems();
    
    this.setState({
      isLoadingState: !this.state.isLoadingState,
    });
  };

  activarModalAsignacionItemsProveedor = () => {
    this.setState({
      modalAsignacionItemsProveedorStatus: !this.state.modalAsignacionItemsProveedorStatus,
    });
  };

  activarModalEditarAsignacionItemsProveedor = (proveedorId) => {
    this.setState({
      proveedorSeleccionado: !isNaN(parseInt(proveedorId)) ? parseInt(proveedorId) : 0,
      modalEditarAsignacionItemsProveedorStatus: !this.state.modalEditarAsignacionItemsProveedorStatus,
    });
  };

  editarProveedor = (IndiceFila) => {
    this.setState((prevState) => {
      const editadoEditarProveedor = { ...prevState.editarProveedor };
      editadoEditarProveedor[IndiceFila] = !editadoEditarProveedor[IndiceFila];
      return { editarProveedor: editadoEditarProveedor };
    });
  };

  crearProcesoCompra = () => {
    this.props.history.push("/admin/solicitud-compra/index");
  };

  obtenerProveedores = (e) => {
    this.setState({ selectProveedoresOptions: parseInt(e.target.value) });
  }

  obtenerArticulos = (e) => {
    const { selectArticulosOptions } = this.state;
    const articulo_id = e.target.value;

    // Verificar si existe
    const haSidoSeleccionado = selectArticulosOptions.some(option => option.articulo_id === articulo_id);

    // Verificar si tiene la opción "checked"
    if (e.target.checked && !haSidoSeleccionado) {
        this.setState(prevState => ({
            selectArticulosOptions: [...prevState.selectArticulosOptions, { 
              requerimiento_id: this.props.match.params.solicitudCompraId , 
              articulo_id: articulo_id,
              proveedor_id: document.getElementById('proveedor').value
            }],
        }));
        document.getElementById(`precioAdjudicacion-${articulo_id}`).removeAttribute("disabled");
    } else if (!e.target.checked && haSidoSeleccionado) {
        this.setState(prevState => ({
            selectArticulosOptions: prevState.selectArticulosOptions.filter(option => option.articulo_id !== articulo_id)
        }));
        document.getElementById(`precioAdjudicacion-${articulo_id}`).setAttribute("disabled", "disabled");
    }
  }

  deleteArticulos = async (e) => {
    let articuloId = 0;
    let requerimientoId = this.props.match.params.solicitudCompraId;
    let proveedorId = this.state.proveedorSeleccionado;
    
    if (!e.target.checked) { 
      articuloId = e.target.value;

      this.setState(prevState => {
        // Verificar que exista el articulo antes de agregar
        const existeArticulo = prevState.articulosParaBorrar.find(item =>
          item.requerimiento_id === requerimientoId &&
          item.articulo_id === articuloId &&
          item.proveedor_id === proveedorId
        );
      
        // Si existe, retorna el anterior guardado en el prevState
        if (existeArticulo) {
          return prevState;
        }
      
        // De lo contrario, agrega un nuevo articulo
        return {
          articulosParaBorrar: [
            ...prevState.articulosParaBorrar,
            {
              requerimiento_id: requerimientoId,
              articulo_id: articuloId,
              proveedor_id: proveedorId
            }
          ]
        };
      });
    }
  }

  selectAllArticulos = (e) => {
    let articulosAdjudicados = this.props.proveedoresInsumosAdjudicados.filter(item => item.proveedor.proveedorId === this.state.proveedorSeleccionado);
    let newArticulos = [];
  
    for (const articulo in articulosAdjudicados) {
      let requerimientoId = articulosAdjudicados[articulo].requerimiento.requerimientoId;
      let articuloId = articulosAdjudicados[articulo].articulo.id;
      let proveedorId = articulosAdjudicados[articulo].proveedor.proveedorId;

      console.log(`${articulosAdjudicados[articulo].articulo.id}`);
  
      document.getElementById(`articuloId-${articulosAdjudicados[articulo].articulo.id}`).setAttribute('checked', 'false');
      document.getElementById(`articuloId-${articulosAdjudicados[articulo].articulo.id}`).checked = false;
  
      const haSidoSeleccionado = this.state.articulos.some(option => option.articulo_id ===  articulosAdjudicados[articulo].articulo.id);
  
      if(!e.target.checked) {
        if(!haSidoSeleccionado) {
          newArticulos.push({
            requerimiento_id: requerimientoId,
            articulo_id: articuloId,
            proveedor_id: proveedorId
          });
          this.setState( {articulosParaBorrar : [...this.state.articulos, ...newArticulos]});
        }
      } else if(e.target.checked) {
        document.getElementById(`articuloId-${articulosAdjudicados[articulo].articulo.id}`).setAttribute('checked', 'true');
        document.getElementById(`articuloId-${articulosAdjudicados[articulo].articulo.id}`).checked = true;
        this.setState( {articulosParaBorrar : []});
      }
    }
  }

  BorrarArticulos = async () => {
    this.props.deleteActividadesArticuloSeguimientoAdjudicado(this.state.articulosParaBorrar);
    window.location.reload();
  };
  
  obtenerPreciosAdjudicados = (e) => {
    const articulo_id = e.target.id.split("-")[1]; // Obtener el ID del artículo del ID del input
    const nuevoPrecio = e.target.value;

    // Actualizar el estado con el nuevo precio adjudicado
    this.setState(prevState => ({
        selectArticulosOptions: prevState.selectArticulosOptions.map(option => {
            if (option.articulo_id === articulo_id) {
                return {
                    ...option,
                    precio_adjudicacion: nuevoPrecio
                };
            }
            return option;
        })
    }));
  }

  guardarProveedorRequerimientoCompra = async () => {
    this.setState({
      desabilitarboton: true
    })

    let proveedorRequerimientoCompra = {
      requerimientoId: this.props.match.params.solicitudCompraId,
      proveedorId: this.proveedoresRef.current.value
    };

    // Guardar los proveedores por orden compras
    await this.props.createProveedorPorRequerimientoCompra(proveedorRequerimientoCompra)

    // Guardar los articulos asignados a cada proveedor con el precio de adjudicacion
    await this.props.setActividadesArticuloSeguimientoAdjudicado(this.state.selectArticulosOptions);
    window.location.reload();
  }

  irAtras = () => {
    this.props.history.goBack();
  }

  eliminarProveedor = (proveedor_requerimiento_compra_id, cantidad_items) => {

    if(cantidad_items > 0) {
      swal("Desasignar proveedores", "Debe de editar el proveedor para poder remover todos los articulos asignados al proveedor antes de eliminar el proveedor del requerimiento","warning");
      return;
    }

    swal({
      title: "¿Está seguro de eliminar este proveedor?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((confirm) => {
      if (confirm) {
        this.props.deleteProveedorRequerimientoCompra(proveedor_requerimiento_compra_id);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    });
  }
  
  actualizarCantidadItems = () => {
    let total = 0;

    this.props.actividadesArticuloSeguimientoPorProveedor.forEach(proveedor => {
      total += parseInt(proveedor.cantidad_items);
    });

    this.setState({ totalItems: total });
  }

  render() {
    const { requerimiento, requerimientoCompra, actividadesArticuloSeguimientoPorProveedor, actividadesArticuloSeguimientoPorProveedorCantidad, proveedorRequerimientoCompra } = this.props;
    const { requerimientoId, numeroSolicitud } = requerimiento;
    const { fechaInicio, numeroProcesoCompra} = requerimientoCompra;
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">

                    <div className="col-md-12 mt-3">
                    <div className="row">
                        <div className="col-6 pb-4 border-right">
                            <h3>Detalles</h3>
                            <hr style={{ marginTop: '0rem' }}/>
                            <label className="text-left" htmlFor="requerimiento">No. Requerimiento</label>
                            <input
                              type="text"
                              className="form-control text-dark"
                              id="requerimiento"
                              ref={this.noRequerimientoRef}
                              required
                              disabled={true}
                              value={numeroSolicitud}
                            />
                            <label className="mt-3" htmlFor="fechaLanzamiento">Fecha de lanzamiento</label>
                            <input
                                type="date"
                                className="form-control text-dark"
                                id="fechaLanzamiento"
                                ref={this.fechaLanzamientoRef}
                                disabled={true}
                                required
                                value={ (fechaInicio !== undefined)  ? fechaInicio.split("T")[0] : '' }
                            />

                            <label className="mt-3" htmlFor="noProcesoCompra">Número de Proceso de Compra</label>
                            <input
                                type="number"
                                className="form-control text-dark"
                                id="noProcesoCompra"
                                ref={this.numeroProcesoCompraRef}
                                disabled={true}
                                value={numeroProcesoCompra}
                            />
                        </div>
                    </div>
                  </div>
                 
                  <div className="col-md-12 mt-5">
                    <h1 className="text-left mb-4">Detalles de la Compra</h1>

                    <h3 className="text-left mb-3">PROVEEDORES</h3>
                   
                    <Table>
                        <thead>
                          <tr>
                            <th>Proveedor</th>
                            <th className="text-center">Cantidad de Items</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {actividadesArticuloSeguimientoPorProveedor.length > 0 && (actividadesArticuloSeguimientoPorProveedor.map((proveedor, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                      type="text"
                                      className="form-control text-dark"
                                      id="nombreProveedor"
                                      ref={this.nombreProveedorRef}
                                      required
                                      value={proveedor.nombre}
                                      disabled={ !this.state.editarProveedor[proveedor.proveedor_id] }
                                  />
                                </td>
                                <td>
                                  <input
                                        type="text"
                                        className="form-control text-dark"
                                        id="cantidadItems"
                                        ref={this.cantidadItemsRef}
                                        required
                                        value={proveedor.cantidad_items}
                                        disabled={ !this.state.editarProveedor[proveedor.proveedor_id] }
                                    />
                                </td>
                                <td>
                                  <a 
                                    className="p-2"
                                    onClick={() => this.activarModalEditarAsignacionItemsProveedor(proveedor.proveedor_id) }
                                  >
                                  <i className="fas fa-pen text-primary"></i>
                                  </a>
                                  <a 
                                    className="p-2"
                                    onClick={ () => this.eliminarProveedor(proveedor.proveedor_requerimiento_compra_id, proveedor.cantidad_items) }
                                  >
                                  <i className={ proveedor.cantidad_items > 0 ? 'fas fa-times text-muted' : 'fas fa-times text-primary' }></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          }))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              <a
                                className="btn btn-primary text-white"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.activarModalAsignacionItemsProveedor();
                                }}
                              >
                              AGREGAR PROVEEDOR 
                              <i className="ml-3 fas fa-user-friends text-white"></i>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              Cantidad Total de Items:
                            </td>
                            <td className="text-center font-weight-bold">
                              {this.state.totalItems}
                            </td>
                          </tr>
                        </tfoot>
                    </Table>

                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.irAtras() }
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            onClick={() => this.crearProcesoCompra()}
                            className="btn btn-primary"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
              )}
            </div>
            <ModalAsignacionItemsProveedor
              proveedores = {this.props.proveedores}
              proveedoresRef = {this.proveedoresRef }
              proveedoresInsumosAdjudicados = {this.props.proveedoresInsumosAdjudicados}
              obtenerProveedores = { this.obtenerProveedores }
              obtenerArticulos = {this.obtenerArticulos }
              obtenerPreciosAdjudicados = {this.obtenerPreciosAdjudicados}
              precioAdjudicacionRef = {this.precioAdjudicacionRef}
              actividadesArticuloSeguimiento = {this.props.requerimiento.actividadesArticuloSeguimiento}
              guardarProveedorRequerimientoCompra = {this.guardarProveedorRequerimientoCompra}
              modalAsignacionItemsProveedorStatus = {
                this.state.modalAsignacionItemsProveedorStatus
              }
              activarModalAsignacionItemsProveedor = {this.activarModalAsignacionItemsProveedor}
              requerimientoId = { this.props.match.params.solicitudCompraId}
              proveedorRequerimientoCompra = { this.props.proveedorRequerimientoCompra }
              desabilitarboton = { this.state.desabilitarboton }
              selectAllArticulos = {this.selectAllArticulos} 
            ></ModalAsignacionItemsProveedor>
            {/* Editar itemns de proveedores existentes en el requerimiento */}
            <ModalEditarAsignacionItemsProveedor
              proveedores = {this.props.proveedores}
              proveedorSeleccionado = {this.state.proveedorSeleccionado}
              proveedoresRef = {this.proveedoresRef }
              proveedoresInsumosAdjudicados = {this.props.proveedoresInsumosAdjudicados}
              obtenerProveedores = { this.obtenerProveedores }
              deleteArticulos = {this.deleteArticulos }
              obtenerPreciosAdjudicados = {this.obtenerPreciosAdjudicados}
              precioAdjudicacionRef = {this.precioAdjudicacionRef}
              actividadesArticuloSeguimiento = {this.props.requerimiento.actividadesArticuloSeguimiento}
              guardarProveedorRequerimientoCompra = {this.guardarProveedorRequerimientoCompra}
              modalEditarAsignacionItemsProveedorStatus = {
                this.state.modalEditarAsignacionItemsProveedorStatus
              }
              BorrarArticulos = { this.BorrarArticulos }
              requerimientoId = { this.props.match.params.solicitudCompraId}
              activarModalEditarAsignacionItemsProveedor = {this.activarModalEditarAsignacionItemsProveedor}
              selectAllArticulos = {this.selectAllArticulos}
            ></ModalEditarAsignacionItemsProveedor>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.signed,
  requerimiento: state.requerimientos.requerimiento,
  requerimientoCompra: state.requerimientos.requerimientoCompra,
  proveedores: state.proveedores.proveedores,
  proveedoresInsumosAdjudicados: state.proveedores.proveedoresInsumosAdjudicados,
  actividadesArticuloSeguimientoPorProveedor:  state.actividades.actividadesArticuloSeguimientoPorProveedor,
  actividadesArticuloSeguimientoPorProveedorCantidad:  state.actividades.actividadesArticuloSeguimientoPorProveedorCantidad,
  proveedorRequerimientoCompra: state.proveedores.proveedorRequerimientoCompra
});

const mapDispatchToProps = (dispatch) => ({
  getRequerimiento: requerimientoId => dispatch(getRequerimiento(requerimientoId)),
  getRequerimientoCompraByRequerimiento: requerimientoId => dispatch(getRequerimientoCompraByRequerimiento(requerimientoId)),
  getProveedores: () => dispatch(getProveedores()),
  createProveedorPorRequerimientoCompra: (data) => dispatch(createProveedorPorRequerimientoCompra(data)),
  getActividadesArticuloSeguimientoPorProveedor: (requerimientoId) => dispatch(getActividadesArticuloSeguimientoPorProveedor(requerimientoId)),
  getCantidadTotalItemsProveedor: (requerimientoId) => dispatch(getCantidadTotalItemsProveedor(requerimientoId)),
  getProveedoresInsumosAdjudicados: () => dispatch(getProveedoresInsumosAdjudicados()),
  getProveedorPorRequerimientoCompra: () => dispatch(getProveedorPorRequerimientoCompra()),
  deleteProveedorRequerimientoCompra: (proveedorRequerimientoCompraId) => dispatch(deleteProveedorRequerimientoCompra(proveedorRequerimientoCompraId)),
  setActividadesArticuloSeguimientoAdjudicado: (data) => dispatch(setActividadesArticuloSeguimientoAdjudicado(data)),
  deleteActividadesArticuloSeguimientoAdjudicado: (data) => dispatch(deleteActividadesArticuloSeguimientoAdjudicado(data))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearProcesoCompras, [
    "Administrador general",
    "Responsables de compras"
  ])
);

import {
  MOSTRAR_ACTIVIDADES,
  MOSTRAR_ACTIVIDAD,
  LOADING_ACTIVIDAD,
  ACTIVIDAD_SUCCEEDED,
  CREAR_ACTIVIDADES,
  EDITAR_ACTIVIDADES,
  ELIMINAR_ACTIVIDADES,
  ACTIVIDAD_FAILED,
  MOSTRAR_ACTIVIDAD_LOCAL,
  ELIMINAR_PRESUPUESTO,
  PRESUPUESTO_SUCCEEDED,
  PRESUPUESTO_FAILED,
  ADD_PRESUPUESTO,
  TOTAL_PONDERACION_AREA_ACTIVIDADES,
  CREAR_ACTIVIDADES_PRODUCTOS,
  MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR,
  MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES,
  CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,
  ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO
} from "./../types";
import { api_base } from "./../../config";

import { getData } from "./../../utils/api-client";

export const getActividades = (productoId) => async (dispatch) => {
  let url = api_base + `api/v1/actividades/productoId/${productoId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ACTIVIDADES, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const createActividades = (actividad) => async (dispatch) => {
  let url = api_base + `api/v1/actividades/crear`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(actividad))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES, payload: resultado.data });
      dispatch({ type: CREAR_ACTIVIDADES_PRODUCTOS, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (!error.response) {
        window.location.reload();
      }
      window.location.reload();
      dispatch({ type: ACTIVIDAD_FAILED });
    });
};
export const updateActividades = (actividad) => async (dispatch) => {
  let url = api_base + `api/v1/actividad/editar`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, actividad)
    .then((resultado) => {
      dispatch({ type: EDITAR_ACTIVIDADES, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const deleteActividades = (actividad) => async (dispatch) => {
  let url = api_base + `api/v1/actividad/eliminar`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(actividad))
    .then((resultado) => {
      dispatch({ type: ELIMINAR_ACTIVIDADES, payload: actividad.actividadId });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};
export const getActividad = (actividadId) => async (dispatch) => {
  let url = api_base + `api/v1/actividad/${actividadId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_ACTIVIDAD, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const getActividadLocal = (actividadId) => async (dispatch) => {
  dispatch({ type: MOSTRAR_ACTIVIDAD_LOCAL, payload: actividadId });
};

export const deletePresupuesto = (presupuesto) => async (dispatch) => {
  let url = `${api_base}api/v1/presupuesto/eliminar`;
  // dispatch({ type:  });
  await getData
    .post(url, JSON.stringify(presupuesto))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_PRESUPUESTO,
        payload: presupuesto.presupuestoId,
      });
      dispatch({ type: PRESUPUESTO_SUCCEEDED });
    })
    .catch((error) => {
      if (error.response) {
        dispatch({ type: PRESUPUESTO_FAILED });
      }
    });
};

export const addPresupuesto = (presupuesto) => async (dispatch) => {
  dispatch({ type: ADD_PRESUPUESTO, payload: presupuesto });
};
export const calcularPonderacionArea = (listaActividades) => async (
  dispatch
) => {
  let totalPonderacion = 0;

  for (let i = 0; i < listaActividades.length; i++) {
    totalPonderacion += parseFloat(listaActividades[i].ponderacionActividad);
    // console.log(parseFloat(listaActividades[i].ponderacionActividad))
    // console.log(totalPonderacion)
  }
  dispatch({
    type: TOTAL_PONDERACION_AREA_ACTIVIDADES,
    payload: totalPonderacion,
  });
};

export const getActividadesArticuloSeguimientoPorProveedor = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimiento/proveedor/${requerimientoId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const getCantidadTotalItemsProveedor = (requerimientoId) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimiento/cantidadItems/proveedor/${requerimientoId}`;
  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .get(url)
    .then((resultado) => {
      dispatch({ type: MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if(error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const setActividadesArticuloSeguimientoAdjudicado = (data) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimientoAdjudicado/crear`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

export const setProveedorInsumosAdjudicados = (data) => async (dispatch) => {
  let url = api_base + `api/v1/adjudicados/crear`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({ type: CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO, payload: resultado.data });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
     //console.log(error)
      if (!error.response) {
        window.location.reload();
      }
      window.location.reload();
      dispatch({ type: ACTIVIDAD_FAILED });
    });
};

export const deleteActividadesArticuloSeguimientoAdjudicado = (data) => async (dispatch) => {
  let url = api_base + `api/v1/actividadesArticuloSeguimientoAdjudicado/delete`;

  dispatch({ type: LOADING_ACTIVIDAD });
  await getData
    .post(url, JSON.stringify(data))
    .then((resultado) => {
      dispatch({
        type: ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,
        payload: resultado.data,
      });
      dispatch({ type: ACTIVIDAD_SUCCEEDED });
    })
    .catch((error) => {
      if (error) {
        dispatch({ type: ACTIVIDAD_FAILED });
      }
    });
};

import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

export const ModalAsignacionItemsProveedor = (props) => {
    const toggle = () => props.activarModalAsignacionItemsProveedor(props.modalAsignacionItemsProveedorStatus);
  
    const {selectAllArticulos, desabilitarboton, proveedorRequerimientoCompra, requerimientoId, modalAsignacionItemsProveedorStatus, actividadesArticuloSeguimiento, proveedores, proveedoresRef, obtenerProveedores, obtenerArticulos, precioAdjudicacionRef, obtenerPreciosAdjudicados, proveedoresInsumosAdjudicados } = props;

    const proveedorIds = proveedoresInsumosAdjudicados.map(item => item.proveedor.proveedorId);
    const proveedorRequerimientoIds = proveedorRequerimientoCompra.map(item => item.proveedor.proveedorId);

    const articulosSeguimientos = [];

    const cuerpo = (items) => {
      if (!articulosSeguimientos.some(item => item === items)) {
        articulosSeguimientos.push(items);
        return (
          <tr key={items.articuloId}>
            <td>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`articuloId-${items.articuloId}`}
                        type="checkbox"
                        onChange={obtenerArticulos}
                        value={items.articuloId}
                    />
                    <label className="custom-control-label" style={{ paddingTop: '0.2rem' }} htmlFor={`articuloId-${items.articuloId}`}>
                        {items.articuloId}
                    </label>
                </div>
            </td>
            <td style={{ whiteSpace: 'unset' }}>{items.nombreArticulo}</td>
            <td>
                <input
                    className="form-control form-control-sm"
                    type="text"
                    id={`precioReferencia-${items.articuloId}`}
                    disabled
                    required
                    value={items.precioReferencia + ' RD$'}
                />
            </td>
            <td className="d-flex flex-row">
                <input
                    className="form-control form-control-sm"
                    type="text"
                    id={`precioAdjudicacion-${items.articuloId}`}
                    ref={precioAdjudicacionRef}
                    disabled
                    defaultValue={items.precioAdjudicacion !== null ? items.precioAdjudicacion : ''}
                    onChange={obtenerPreciosAdjudicados}
                />
                <span className="p-2">RD$</span>
            </td>
        </tr>
        )
      }
    }

    return (
        <Modal isOpen={modalAsignacionItemsProveedorStatus} toggle={toggle} size="lg" backdrop="static">
          <ModalHeader className="block-example border-bottom " toggle={toggle}>
            ASIGNACIÓN DE ITEMS DEL PROVEEDOR
          </ModalHeader>
          <ModalBody>
            {proveedores.filter((prov) => !proveedorRequerimientoIds.includes(prov.proveedorId)).length === 0 && (
              <div className="col-md-4 mb-3">
                <p>No hay disponibles</p>
              </div>
            )}
            {proveedores.filter((prov) => !proveedorRequerimientoIds.includes(prov.proveedorId)).length !== 0 && (
              <>
                <div className="col-md-4 mb-3">
                  <label>Seleccione a un proveedor</label>
                  <select
                    id="proveedor"
                    className="form-control select form-control-sm text-capitalize"
                    name="proveedores"
                    ref={proveedoresRef}
                    onChange={obtenerProveedores}
                  >
                    {proveedores.map((prov) => (
                      !proveedorRequerimientoIds.includes(prov.proveedorId) && (
                        <option
                          key={prov.proveedorId}
                          value={prov.proveedorId}
                          selected={proveedorIds.includes(prov.proveedorId)}
                        >
                          {prov.nombre}
                        </option>
                      )
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Table style={{ tableLayout: 'fixed' }}>
                      <thead>
                        <tr>
                          <th>
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="subclase"
                                type="checkbox"
                                onChange={(e) => selectAllArticulos(e)}
                                defaultChecked={false}
                              />
                              <label className="custom-control-label" style={{ fontSize: '0.65rem', paddingTop: '0.4rem' }} htmlFor="subclase">
                                Sub-clase
                              </label>
                            </div>
                          </th>
                          <th>Nombre</th>
                          <th>Precio de referencia</th>
                          <th>Precio de adjudicación</th>
                        </tr>
                      </thead>
                      <tbody>
                        {proveedores.map((proveedor) => {
                          if (!proveedorRequerimientoIds.includes(proveedor.proveedorId)) {
                            return (
                              actividadesArticuloSeguimiento && actividadesArticuloSeguimiento
                                .filter(item => !proveedoresInsumosAdjudicados.some(adjudicado => adjudicado.articulo.id === item.articuloId))
                                .map((items) => (
                                  cuerpo(items)
                                ))
                            );
                          }
                          return null;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="row">
              <div className="">
                <button
                  className="btn btn-danger"
                  disabled={desabilitarboton}
                  onClick={(e) => {
                    e.preventDefault();
                    props.activarModalAsignacionItemsProveedor(props.modalAsignacionItemsProveedor);
                  }}
                >
                  Cancelar
                </button>
              </div>
              {proveedores.filter((prov) => !proveedorRequerimientoIds.includes(prov.proveedorId)).length !== 0 && (
                <div className="ml-3">
                    <button
                    className="btn btn-primary"
                    disabled={desabilitarboton}
                    onClick={(e) => {
                        e.preventDefault();
                        props.guardarProveedorRequerimientoCompra(requerimientoId);
                    }}
                    >
                    Guardar
                    </button>
                </div>
                )}
            </div>
      
          </ModalFooter>
        </Modal>
      );
      
};

import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import Select from 'react-select'
import {
  Table,
  Container,
} from "reactstrap";
import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { getListaTipoCompras } from "../../redux/actions/tipos-compras-actions";
import { getListaEstados } from "../../redux/actions/estados-actions";
import { getListaUsuarios } from "../../redux/actions/usuarios-actions";

class CrearRequerimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      estatusEnviar: false,
      areaid2:0,
      listaUsuariosState: [],

      editarArticulo: {}
    };
  }

  componentWillReceiveProps(next_props, nextState) {
    this.setState({ listaUsuariosState: this.props.usuarios });
  }
  
  componentDidMount() {
    this.cargarDatos();
   
    // console.log(this.props)
    this.setState({areaid2:this.props.user.areaOrganizacional.areaOrganizacionalId})
  }

  cargarDatos = async () => {
    await this.props.getListaUsuarios();
    this.setState({ listaUsuariosState: this.props.usuarios }, () =>
      this.validarPaginacion()
    );

    this.setState({ isLoading: false });
  };

  cargarDatos = async (areafiltro) => {
    await this.props.getListaTipoCompras();
    await this.props.getListaEstados();
    await this.props.getListaUsuarios();

    this.setState({ listaUsuariosState: this.props.usuarios });

    this.setState({ isLoadingState: false });
  };

  cargarInformeTrimestral = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  editarArticulo = (IndiceFila) => {
    this.setState((prevState) => {
      const editadoEditarArticulo = { ...prevState.editarArticulo };
      editadoEditarArticulo[IndiceFila] = !editadoEditarArticulo[IndiceFila];
      return { editarArticulo: editadoEditarArticulo };
    });
  };

  eliminarArticulo = (IndiceFila) => {
    alert('Proceso para eliminar')
  };

  activarModalInformeTrimestral = () => {
    this.CargarTrimestre();
    this.setState({
      modalInformeTrimestralStatus: !this.state.modalInformeTrimestralStatus,
    });
  };

  CargarTrimestre = () => {
    let informesTrimestral = this.props.informesTrimestrales;
    let trimestreState = [...this.state.trimestres];
    for (let i = 0; i < trimestreState.length; i++) {
      for (let m = 0; m < informesTrimestral.length; m++) {
        if (
          informesTrimestral[m].trimestre.trimestreId ===
          trimestreState[i].trimestreId
        ) {
          trimestreState.splice(i, 1);
          this.setState({ trimestresEntity: trimestreState });
          i = -1;
          break;
        }
      }
    }
    if (
      this.state.trimestresEntity.length === 0 &&
      informesTrimestral.length === 0
    ) {
      this.setState({ trimestresEntity: this.state.trimestres });
    }
  };

  seleccionarTrimestre = (trimestreId) => {
    this.setState({ trimestreId: trimestreId });
  };

  enviarInforme = async (informe) => {
    this.setState({ isLoadingState: true });

    swal({
      title: "Está seguro de enviar a revisión el informe del trimestre?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
        let informeTrimestral = {
          informeTrimestralId: informe.informeTrimestralId,
          nivelAprobacionId: this.props.user.nivelAprobacion.padre,
          envio: true,
          comentario: "Enviado para aprobación",
        };
        await this.props.updateInformeTrimestral(informeTrimestral);
        this.setState({ isLoadingState: false });
      } else {
        this.setState({ isLoadingState: false });
      }
    });
  };

  eliminarInformeTrimestral = async (informeTrimestralId) => {

    swal({
      title: "Está seguro de querer eliminar este informe del trimestre?",
      text: "No podrá ser recuperado luego de ser eliminado",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (confirm) => {
      if (confirm) {
         this.setState({ isLoadingState: true });
         await this.props.deleteInformeTrimestral(informeTrimestralId);
         this.setState({ isLoadingState: false });
      }
    });
  };

  devolverInforme = async (informeTrimestralId, comentario) => {
    let informeTrimestral = {
      informeTrimestralId: informeTrimestralId,
      nivelAprobacionId: this.props.user.nivelAprobacion.hijo,
      envio: true,
      comentario,
    };

    await this.props.updateInformeTrimestral(informeTrimestral);
  };

  validarEstatusEnviado = (informeTrimestral) => {
    let resultado =
      informeTrimestral.nivelAprobacion.nivelAprobacionId !==
      this.props.user.nivelAprobacion.nivelAprobacionId
        ? true
        : false;
    return resultado;
  };
  validarGuardarInforme = () => {
    if (this.state.trimestreId.length === 0) {
      swal("Informe Trimestral", "El campo trimestre es requerido para el informe del trimestre","error");

      return false;
    }

    if (this.state.file.length === 0) {
      swal("Informe Trimestral", "El campo de archivo es un valor requerido para el informe del trimestre","error");

      return false;
    }
    return true;
  };

  guardarInformeTrimestral = async () => {
    if (this.validarGuardarInforme()) {
      this.setState({ isLoadingState: true });
      this.activarModalInformeTrimestral();

      let informeTrimestral = {
        trimestreId: this.state.trimestreId,
        nivelAprobacionId: this.props.user.nivelAprobacion.nivelAprobacionId,
        nombreImagen: this.state.documento[0],
        areaOrganizacionalId: this.props.user.areaOrganizacional
          .areaOrganizacionalId,
        poaId: this.props.poaActivo.poaId,
      };

      const fd = new FormData();
      if (this.state.file.length > 0) {
        for (let i = 0; i < this.state.file.length; i++) {
          fd.append(`documento[]`, this.state.file[i], this.state.documento[i]);
        }
      }

      Object.keys(informeTrimestral).forEach(function (item) {
        fd.append(item, informeTrimestral[item]);
      });

      this.setState({ isLoadingState: false });
    }
  };

  validar;

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
               
                <div className="row">
                    <div className="col-md-12 mt-3">
                      <div className="row">
                          <div className="col-6 pb-4 border-right">
                              <h3>Detalles</h3>
                              <hr style={{ marginTop: '0rem' }}/>

                              <div className="form-group mt-4">
                                  <label htmlFor="analistaAsignado">Analista asignado</label>
                                  {/* <select
                                      id="analistaAsignado"
                                      className="form-control text-capitalize"
                                      ref={this.localRef}
                                  >
                                    {this.props.usuarios.map((usuario) => (
                                      <option
                                        key={usuario.id}
                                        value={usuario.id}
                                      >
                                        {usuario.name}
                                      </option>
                                    ))}
                                  </select> */}
                                  <Select options={ this.props.usuarios.map(usuario => ({
                                    value: usuario.id,
                                    label: usuario.name
                                  })) }/>
                              </div>

                              <div className="form-group mt-4">
                                  <label htmlFor="tipoProcesoCompra">Tipo proceso de compra</label>
                                  <select
                                      id="tipoProcesoCompra"
                                      className="form-control"
                                      ref={this.localRef}
                                  >
                                      {this.props.tiposCompras.map((tipoCompra) => (
                                    <option
                                      key={tipoCompra.tipoCompraId}
                                      value={tipoCompra.tipoCompraId}
                                    >
                                      {tipoCompra.nombre}
                                    </option>
                                  ))}
                                  </select>
                              </div>

                              <div className="form-group mt-4">
                                  <label htmlFor="estatusSecundario">Estatus secundario</label>
                                  <select
                                      id="estado"
                                      className="form-control"
                                      ref={this.localRef}
                                  >
                                      {this.props.estados.map((estado) => (
                                    <option
                                      key={estado.estadoId}
                                      value={estado.estadoId}
                                    >
                                      {estado.nombre}
                                    </option>
                                  ))}
                                  </select>
                              </div>

                          </div>
                          <div className="col-6 pb-4">
                              <h3>-</h3>
                              <hr style={{ marginTop: '0rem' }} />

                              <div className="form-group mt-4"> 
                                <label className="text-left" htmlFor="numeroSigef">Número De SIGEF</label>
                                <input
                                    type="number"
                                    className="form-control text-dark"
                                    id="numeroSigef"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>
                            <div className="form-group mt-4"> 
                                <label className="text-left" htmlFor="numeroSigef">Número De Solicitud</label>
                                <input
                                    type="number"
                                    className="form-control text-dark"
                                    id="numeroSigef"
                                    // ref={this.actividadRef}
                                    // defaultValue={descripcion}
                                    // readOnly={this.state.actividadEdit}
                                    required
                                    value=""
                                />
                            </div>

                            <div className="custom-control custom-checkbox mt-4">
                                <input type="checkbox" className="custom-control-input" id="procesoPlurianual" />
                                <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                            </div>

                          </div>
                      </div>
                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            // onClick={() => this.eliminarPoa(poaId)}
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            // onClick={() => this.eliminarPoa(poaId)}
                            className="btn btn-primary"
                          >
                            GUARDAR
                          </button>
                        </div>
                      </div>                  
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  tiposCompras: state.tiposCompras.tiposCompras,
  estados: state.estados.estados,
  usuarios: state.usuarios.usuarios,
});

const mapDispatchToProps = (dispatch) => ({
    getListaTipoCompras: () => dispatch(getListaTipoCompras()),
    getListaEstados: () => dispatch(getListaEstados()),
    getListaUsuarios: () => {
      dispatch(getListaUsuarios());
    },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(CrearRequerimiento, [
    "Administrador general",
    "Responsables de compras"
  ])
);

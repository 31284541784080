import {
  CREAR_PRODUCTOS,
  MOSTRAR_PRODUCTOS,
  MOSTRAR_PRODUCTO,
  EDITAR_PRODUCTO,
  ELIMINAR_PRODUCTO,
  LOADING_PRODUCTO,
  PRODUCTO_SUCCEEDED,
  MOSTRAR_PRODUCTOS_ADMINISTRACION,
  TOTAL_PONDERACION_AREA_PRODUCTOS,
  TOTAL_PONDERACION_GENERAL_PRODUCTOS,
  CREAR_ACTIVIDADES_PRODUCTOS,
  TOTAL_PONDERACION_PRODUCTO_ACTIVIDADES,
  MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION,
  CLEAN_PRODUCTOS_ADMINISTRACION,
  MOSTRAR_PRODUCTOS_INSUMOS
} from "./../types";

import initialState from "./../initial-state";

const { productos } = initialState;

const productosReducer = (state = productos, action) => {
  switch (action.type) {
    case LOADING_PRODUCTO:
      return {
        ...state,
        isLoading: true,
      };
    case PRODUCTO_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case MOSTRAR_PRODUCTO:
      return {
        ...state,
        producto: action.payload,
      };
    case MOSTRAR_PRODUCTOS:
      return {
        ...state,
        productos: action.payload,
      };
    case MOSTRAR_PRODUCTOS_INSUMOS:
      return{
        ...state,
        productosActividadInsumos:action.payload
      }
    case MOSTRAR_TOTAL_PRODUCTOS_ADMINISTRACION:
      return {
        ...state,
        totalProductosAdministracion: action.payload,
      };
    case CREAR_PRODUCTOS:
      return {
        ...state,
        productos: [...state.productos, action.payload],
      };
    case EDITAR_PRODUCTO: {
      return {
        ...state,
        productos: state.productos.map((producto) =>
          producto.productoId === action.payload.productoId
            ? (producto = action.payload)
            : producto
        ),
      };
    }
    case TOTAL_PONDERACION_PRODUCTO_ACTIVIDADES: {
      return {
        ...state,
        productos: state.productos.map((producto) =>
          producto.productoId === action.payload.productoId
            ? {
                ...producto,
                totalPonderacionActividad:
                  action.payload.totalPonderacionActividades,
              }
            : producto
        ),
      };
    }
    case ELIMINAR_PRODUCTO:
      return {
        ...state,
        productos: [
          ...state.productos.filter(
            (producto) => producto.productoId !== action.payload
          ),
        ],
      };
    case MOSTRAR_PRODUCTOS_ADMINISTRACION:
      return {
        ...state,
        productosAdministracion: action.payload,
      };
    case CLEAN_PRODUCTOS_ADMINISTRACION:
      return {
        ...state,
        productosAdministracion: [],
      };
    case TOTAL_PONDERACION_AREA_PRODUCTOS:
      return {
        ...state,
        totalPonderacionProductos: action.payload,
      };
    case TOTAL_PONDERACION_GENERAL_PRODUCTOS:
      return {
        ...state,
        totalPonderacionGeneralProductos: action.payload.totalPoderacion,
      };
    case CREAR_ACTIVIDADES_PRODUCTOS:
      return {
        ...state,
        producto: {
          ...state.producto,
          actividades: [...state.producto.actividades, action.payload],
        },
      };
    default:
      return state;
  }
};

export default productosReducer;

import React from "react";
import { Table } from "reactstrap";
import numbro from "numbro";

const ListaDetalleInsumos = props => {
  return (
    <React.Fragment>
      <Table
        style={{ backgroundColor: "#fff", color: "black" }}
        responsive
        className="table-striped"
      >
        <thead>
          <tr>
            <th>Insumo</th>
            <th>Subclase</th>
            <th>Cuenta</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {props.insumos.map(insumo => (
            <React.Fragment key={insumo.presupuestoId}>
              <tr key={insumo.presupuestoId} className="text-dark">
                <td>{insumo.insumo}</td>
                <td>{insumo.subclase}</td>
                <td>{insumo.cuenta}</td>
                <td>{'RD$ '+numbro(insumo.valor*insumo.frecuencia*insumo.cantidad).format({thousandSeparated:true})}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default ListaDetalleInsumos;

import {
  MOSTRAR_TIPOS_COMPRAS,
  TIPOS_COMPRAS_FAILED,
  LOADING_TIPOS_COMPRAS,
  TIPOS_COMPRAS_SUCCESS,
} from "../types";
import initialState from "../initial-state.js";
const { tiposCompras } = initialState;

const tipoComprasReducer = (state = tiposCompras, action) => {
  switch (action.type) {
    case MOSTRAR_TIPOS_COMPRAS:
      // console.log("action.payload",action.payload)
      return {
        ...state,
        tiposCompras: action.payload
      };
    case LOADING_TIPOS_COMPRAS:
      return {
        ...state,
        isLoading: true
      };
    case TIPOS_COMPRAS_SUCCESS:
    case TIPOS_COMPRAS_FAILED:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default tipoComprasReducer;

import {
    MOSTRAR_INSUMOS,
    LOADING_INSUMOS,
    INSUMOS_FAILED,
    INSUMOS_SUCCEED,
    MOSTRAR_OPERACIONES,
    LOADING_OPERACIONES,
    OPERACIONES_SUCCEED,
    OPERACIONES_FAILED,
    ELIMINAR_PRESUPUESTO,
    PRESUPUESTO_SUCCEEDED,
    PRESUPUESTO_FAILED,
    AGREGAR_INSUMOS_TEMPORALES,
    LIMPIAR_INSUMOS_TEMPORALES,
} from "./../types";
import { getData } from "./../../utils/api-client";
import { api_base } from "./../../config";

export const getMaestroInsumos = (
    cuenta,
    insumo,
    operacion,
    perPage=200,
    pagina=0
) => async dispatch => {
    //  let url = `${api_base}api/v1/maestrosInsumos/${cuenta}/${insumo}/${operacion}`;
    // console.log(`${api_base}api/v1/insumos/${pagina}/${perPage}/${insumo}`);
    let insumoFilter = {
        pagina,
        perPage,
        insumo
    }
    let url = `${api_base}api/v1/insumos`;
    await getData
    .post(url,insumoFilter)
    .then(resultado => {
        // console.log("insumos ",resultado);
        dispatch({ type: MOSTRAR_INSUMOS, payload: resultado.data });

        dispatch({ type: INSUMOS_SUCCEED });
    })
    .catch(error => {
        if (error) {
            dispatch({ type: INSUMOS_FAILED });
        }
    });
};

export const getMaestroOperaciones = () => async dispatch => {
    let url = `${api_base}api/v1/maestrosoperaciones`;
    dispatch({ type: LOADING_OPERACIONES });
    await getData
    .get(url)
    .then(resultado => {
        dispatch({ type: MOSTRAR_OPERACIONES, payload: resultado.data });
        dispatch({ type: OPERACIONES_SUCCEED });
    })
    .catch(error => {
        if (error) {
            dispatch({ type: OPERACIONES_FAILED });
        }
    });
};

export const agregarInsumosTemporales = (insumos)=>async dispatch=>{
    // console.log("Desde action",insumos)
     dispatch({ type: AGREGAR_INSUMOS_TEMPORALES, payload:insumos});
}

export const limpiarInsumosTemporales = ()=>async dispatch=>{
    dispatch({ type:LIMPIAR_INSUMOS_TEMPORALES});
}
import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import {
  Table,
  Container,
} from "reactstrap";
import Pagination from "react-js-pagination";

import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";

import { ModalRecepcionBienesServicios } from "../utils/ModalRecepcionBienesServicios";
import ExpedientesFisicosLista from './ExpedientesFisicosLista';

import { getRequerimientos } from "../../redux/actions/requerimientos-actions";
class ExpedientesFisicosIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingState: true,
      trimestres: [],
      trimestresEntity: [],
      modalInformeTrimestralStatus: false,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
      trimestreId: "",
      estatusEnviar: false,
      areaid2:0,

      listaExpedientesFisicosState: [],
      listaExpedientesFisicosPageState: [],
      activePage: 1,
      totalPages: 0,
      itemPerPage: 10,
    };
  }

  componentDidMount() {
    this.cargarDatos();
  }

  cargarDatos = async () => {
    this.setState({ isLoadingState: false });

    await this.props.getRequerimientos();

    this.setState({ listaExpedientesFisicosState: this.props.expedientesFisicos, isLoadingState: false }, () =>
      this.validarPaginacion()
    );

  };

  validarPaginacion = (page = 1, elementos = 10) => {
    let index = elementos * page - elementos;
    let listaExpedientesFisicos = [];

    elementos = this.state.listaExpedientesFisicosState.length < elementos ? this.state.listaExpedientesFisicosState.length : elementos;

    for (let i = 0; i < elementos; i++) {
      const contrato = this.props.expedientesFisicos.find(ef => ef.requerimientoId == this.state.listaExpedientesFisicosState[index].requerimientoId);
      if (contrato) {
        listaExpedientesFisicos.push({ ...this.state.listaExpedientesFisicosState[index], contrato });
      }
      index++;
  }

    let totalPages = Math.ceil(
      this.state.listaExpedientesFisicosState.length / elementos
    );

    this.setState({
      listaExpedientesFisicosPageState: listaExpedientesFisicos,
      activePage: page,
      totalPages,
      itemPerPage: elementos,
    });
  };

  render() {
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (
                <div className="row">
                  <div className="col-md-12 text-left ">
                    <h3>EXPEDIENTES FISICOS A EVALUAR POR DIRECCIÓN JURÍDICA</h3>
                  </div>
                  <div className="col-md-12 mt-3">
                    <Table>
                      <thead>
                        <tr>
                          <th>No. Producto</th>
                          <th>Analista Asignado</th>
                          <th>No. Requerimiento</th>
                          <th>Área</th>
                          <th>Fecha de Entrada</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                          {this.state.listaExpedientesFisicosPageState.map(
                          (solicitud) =>
                            solicitud != null &&
                            solicitud != undefined && (
                              <ExpedientesFisicosLista
                                key={solicitud.requerimientoId}
                                requerimiento={solicitud}
                                user={this.props.user}
                              />
                            )
                        )}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-center">
                      <Pagination
                        prevPageText="ant"
                        nextPageText="sig"
                        firstPageText="prime"
                        lastPageText="ultimo"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemPerPage}
                        totalItemsCount={this.state.listaExpedientesFisicosPageState.length}
                        onChange={this.validarPaginacion.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <ModalRecepcionBienesServicios
              // guardarInformeTrimestral={this.guardarInformeTrimestral}
              // trimestres={this.state.trimestresEntity}
              // seleccionarTrimestre={this.seleccionarTrimestre}
              // modalInformeTrimestralStatus={
              //   this.state.modalInformeTrimestralStatus
              // }
              // cargarInformeTrimestral={this.cargarInformeTrimestral}
              // activarModalInformeTrimestral={this.activarModalInformeTrimestral}
            ></ModalRecepcionBienesServicios>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  expedientesFisicos: state.requerimientos.requerimientos,
});

const mapDispatchToProps = (dispatch) => ({
  getRequerimientos: () => dispatch(getRequerimientos())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(ExpedientesFisicosIndex, [
    "Administrador general",
    "Responsables de compras"
  ])
);

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "react-moment";

class SolicitudCompras extends Component {
  state = {};

  render() {
    const { requerimientoId, numeroSolicitud, fechaCreacion, estado, analista, tipoCompras, sigef,requerimiento } = this.props.requerimiento;
    
    return (
      estado.estadoId>2&&(
      <tr className="text-left" key={requerimientoId}>
        <td>{numeroSolicitud}</td>
        <td>{estado.nombre}</td>
        <td>{requerimiento[requerimiento.length-1].area.nombre}</td>
        <td>
            <Moment
              format="DD/MM/YYYY"
              date={{fechaCreacion}}
            >
            </Moment>
        </td>
        <td>{(tipoCompras && tipoCompras.nombre !== null) ? tipoCompras.nombre : '-'}</td>
        <td>{(analista && analista.name !== null) ? analista.name : '-' }</td>
        <td></td>
        <td>{sigef}</td>
        <td className="d-flex flex-row">
          <Link
            to={{
              pathname: `recepcion-remision-compra/validacion/${requerimientoId}`,
              state: `/admin/solicitud-compra/recepcion-remision-compra/validacion/:solicitudCompraId`,
            }}
            className="p-2"
          >
            <i className="fas fas fa-angle-double-right text-primary"></i>
          </Link>

          <Link
            to={{
              pathname: `/admin/solicitud-requerimiento/editar/${requerimientoId}`,
              state: `/admin/solicitud-compra/editar/:solicitudCompraId`,
            }}
            className="p-2"
          >
            <i className="fas fa-pen text-primary"></i>
          </Link>

          {  tipoCompras ? 
            <Link
              to={{
                pathname: `/admin/solicitud-compra/departamento-administrativo/ProcesoCompralanzamientoCompra/${requerimientoId}`,
                state: `/admin/solicitud-compra/departamento-administrativo/ProcesoCompralanzamientoCompra/:solicitudCompraId`,
              }}
              className="p-2"
            >
              <i className="far fa-calendar text-primary"></i>
            </Link>
          : <a href="#" className="p-2">
              <i className="far fa-calendar text-muted"></i>
            </a> 
          }

          {  tipoCompras ? 
            <Link
              to={{
                pathname: `crear-proceso-compra/${requerimientoId}`,
                state: `/admin/solicitud-compra/crear-proceso-compra/:solicitudCompraId`,
              }}
              className="p-2"
            >
              <i className="fas fa-check-double text-primary"></i>
            </Link>
          : <a href="#" className="p-2">
              <i className="fas fa-check-double text-muted"></i>
            </a> 
          }

          {  tipoCompras ? 
              <Link
                to={{
                  pathname: `gestion-bienes-servicios/crear-servicios/${requerimientoId}`,
                  state: `/admin/gestion-bienes-servicios/crear-servicios/:solicitudCompraId`,
                }}
                className="p-2"
              >
              <i className="fas fa-people-carry text-danger"></i>
              </Link>
            : <a href="#" className="p-2">
                <i className="fas fa-people-carry text-muted"></i>
              </a> 
            }
        </td>
      </tr>
      )
    );
  }
}

export default connect(null, {})(SolicitudCompras);

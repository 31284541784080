import { MOSTRAR_TIPOS_COMPRAS, LOADING_TIPOS_COMPRAS, TIPOS_COMPRAS_FAILED } from "../types";
import { api_base } from "../../config";
import { getData } from "../../utils/api-client";

export const getListaTipoCompras = () => async dispatch => {

    let url = api_base + "api/v1/tipocompras";

    await getData
        .get(url)
        .then(resultado => {
            // console.log("resultado.data",resultado.data)
            dispatch({ type: LOADING_TIPOS_COMPRAS });
            dispatch({ type: MOSTRAR_TIPOS_COMPRAS, payload: resultado.data });
        })
        .catch(error => {
            
                dispatch({ type: TIPOS_COMPRAS_FAILED });
             
        });
};

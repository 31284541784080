import React, { Component } from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import Select from 'react-select'
import {
  Container,
  Table
} from "reactstrap";
import { Authorization } from "../../utils/Authorization";
import Loading from "../utils/Loading";
import Insumos from "./Insumos";
import numbro from "numbro";
import { getListaTipoCompras } from "../../redux/actions/tipos-compras-actions";
import { getListaEstados } from "../../redux/actions/estados-actions";
import { getListaUsuarios, getListaAnalistasAsignados } from "../../redux/actions/usuarios-actions";
import { getRequerimiento, updateRequerimientos, deletePresupuesto } from "../../redux/actions/requerimientos-actions";
import { ModalDetalleArticulo } from "./ModalDetalleArticulo";

class EditarRequerimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editarArticulo: {},
      modalDetalleArticulo: false,
      presupuesto: {},
      editarForm: false,
      isLoadingState: false,
      anlistaId: 0,
      procesoCompraId: 0,
      estadoId: 0,
      sigef: ""
    };
  }

  // Ref
  analistaRef = React.createRef();
  tipoCompraRef = React.createRef();
  sigefRef = React.createRef();
  numeroSolicitudRef = React.createRef();
  esProcesoPlurianual = React.createRef();
  estadoRef = React.createRef();

  componentDidMount() {
    this.setState({ isLoadingState: true });
    this.cargarDatos();
    this.validarEditar();
  }

  actualizarRequerimiento = async (e) => {
    e.preventDefault();
    this.setState({ isLoadingState: true });
    let editarRequerimiento = {
      analistaId: this.state.analistaId,
      estadoId: this.state.estadoId > 0 ? this.state.estadoId : this.props.requerimiento.estado.estadoId,
      sigef: this.state.sigef,
      numeroSolicitud: this.props.requerimiento.numeroSolicitud,
      tipoCompraId: this.state.tipoCompraId,
      requerimientoProcesoPlurianualId: document.getElementById("procesoPlurianual").checked,
      requerimientoId: this.props.requerimiento.requerimientoId,
      selectedFile: null,
      file: [],
      fecha: "",
      documento: [],
    };

    await this.props.updateRequerimientos(editarRequerimiento);
    this.irAtras();
    // this.props.updateRequerimientos(editarRequerimiento).then(resultado => {
    //   this.props.history.push("/admin/solicitud-requerimiento/listado");
    // });
  };

  cargarDatos = async () => {
    const { requerimientoId } = this.props.match.params;
    await this.props.getListaEstados();
    await this.props.getListaTipoCompras();
    await this.props.getRequerimiento(requerimientoId);
    await this.props.getListaAnalistasAsignados();
    this.setState({ isLoadingState: false });
  };

  validarButtonEliminar = () => {
    const { requerimiento, estado } = this.props.requerimiento
    if (estado.estadoId > 2 || requerimiento[0].area.areaOrganizacionalId != this.props.user.areaOrganizacional.areaOrganizacionalId) {
      return false
    } else {
      return true
    }
  }

  ActivarModalDetalleArticulo = (presupuesto) => {

    this.setState({
      modalDetalleArticulo: !this.state.modalDetalleArticulo,
      presupuesto: presupuesto
    });
  }

  irAtras = () => {
    this.props.history.goBack();
  }

  validarEditar = () => {
    let filter = ['Responsables de compras', 'Analista Compras', 'Administrador general'];
    let resultado = this.props.user.roles.some(i => filter.includes(i));
    this.setState({ editarForm: resultado });
  }

  eliminarPresupuesto = (presupuestoId) => {
    if (this.props.requerimiento.actividadesArticuloSeguimiento.length < 1) {
      swal("Advertencia",
        "El producto no puede ser eliminado si el requerimiento consta de uno solo; se debe eliminar el requerimiento completo.",
        "error"
      )
    } else {

      swal({
        title: "Eliminar Insumo?",
        text: "Esta seguro de eliminar el insumo, esta accion no es reversible?",
        icon: "warning",
        buttons: [
          'Cancelar',
          'Aceptar'
        ],
        dangerMode: true,
      }).then(async (isConfirm) => {
        if (isConfirm) {
          await this.props.deletePresupuesto(presupuestoId);
          swal("Exito!", "Fue actualizado el requerimiento", "success");
          setTimeout(() => { window.location.reload(); }, 1200)

        }
      })

      // swal({
      //   title: "Confirmar",
      //   text:"Esta seguro de eliminar el producto, esta accion no es reversible?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Guardar",
      //   denyButtonText: `Cancelar`
      // }).then((result) => {
      //   /* Read more about isConfirmed, isDenied below */
      //   if (result.isConfirmed) {
      //     this.props.deletePresupuesto(presupuestoId);
      //     swal("Exito!", "Fue actualizado el requerimiento", "success");
      //      window.location.reload();

      //   } else if (result.isDenied) {
      //   }
      // });

    }
  }
  cargarEspecificacionesTecnicas = (informe) => {
    let files = [];
    let documentos = [];
    if (informe.target.files.length > 0) {
      for (let i = 0; i < informe.target.files.length; i++) {
        files.push(informe.target.files[i]);
        documentos.push(informe.target.files[i].name);
      }
      this.setState({
        file: files,
        documento: documentos,
      });
    }
    else {
      this.setState({
        file: "",
        documento: "",
      });
    }
  };

  CalcularTotalInsumos = (articulos)=>{
    let  articulosCount = articulos.filter(x=> x.estatus);
    let totalInsumos = articulosCount.reduce((total,valor)=>total+valor.presupuesto.totalCosto,0);
   return `RD$ ${numbro(totalInsumos).format({thousandSeparated:true})}`;

  }

  render() {
    // console.log("usersss", this.props.user);

    const { isLoading, requerimiento } = this.props;
    const { analista, estado, tipoCompras, sigef, numeroSolicitud, actividadesArticuloSeguimiento } = requerimiento;
    //  console.log(requerimiento);
    return (
      <div className="pb-6 pt-5 pt-md-7">
        <Container fluid>
          <React.Fragment>
            <div className="container-fluid bg-white border py-3">
              {this.state.isLoadingState ? (
                <Loading></Loading>
              ) : (

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <form  >
                      <div className="row">
                        <div className="col-6 pb-4 border-right">
                          <h3>Modificar Requerimiento Compra</h3>
                          <hr style={{ marginTop: '0rem' }} />
                          <div className="form-group mt-4">
                            <label className="text-left" htmlFor="numeroSigef">Número De Solicitud</label>
                            <input
                              type="text"
                              className="form-control text-dark"
                              id="numeroSolicitud"
                              ref={this.numeroSolicitudRef}
                              defaultValue={numeroSolicitud}
                              readOnly
                              // readOnly={this.state.actividadEdit}
                              required
                            />
                          </div>
                          <div className="form-group mt-4">
                            <label htmlFor="analista">Analista asignado</label>
                            {this.state.editarForm ? (
                              // <Select options={this.props.usuariosAnalistasAsignados && (this.props.usuariosAnalistasAsignados.map(usuario => ({
                              //   value: usuario.id,
                              //   label: usuario.name
                              // })))}
                              //   value={requerimiento.analista ? { value: analista.id, label: analista.name } : null}
                              //   ref={this.analistaRef}

                              // />
                              <select
                                id="analista"
                                className="form-control"
                                ref={this.analistaRef}
                                // value={analista && analista.id > 0 ? { value: analista.id, label: analista.name } : null}
                                defaultValue={analista ? analista.id : 0}
                                onChange={(e) => {
                                  this.setState({ analistaId: e.currentTarget.value })
                                }}
                                disabled={!this.state.editarForm}
                              >
                                <option
                                  key={0}
                                  value={0}
                                >--Seleccionar Analista--</option>
                                {this.props.usuariosAnalistasAsignados && (this.props.usuariosAnalistasAsignados.map((analista) => (
                                  <option
                                    key={analista.id}
                                    value={analista.id}
                                  >
                                    {analista.name}
                                  </option>
                                )))}
                              </select>

                            ) : (
                              <input
                                type="text"
                                className="form-control text-dark"
                                id="analista"
                                ref={this.analistaRef}
                                defaultValue={requerimiento.analista ? requerimiento.analista.name : "-"}
                                readOnly
                              // readOnly={this.state.actividadEdit}

                              />
                            )}

                          </div>

                          <div className="form-group mt-4">
                            <label htmlFor="tipoProcesoCompra">Tipo proceso de compra</label>
                            <select
                              id="tipoProcesoCompra"
                              className="form-control"
                              ref={this.tipoCompraRef}
                              // value={tipoCompras && tipoCompras.tipoCompraId > 0 ? { value: tipoCompras.tipoCompraId, label: tipoCompras.nombre } : null}
                              defaultValue={tipoCompras ? tipoCompras.tipoCompraId : 0}
                              onChange={(e) => {
                                // console.log("tipoCompra",e.currentTarget.value)
                                this.setState({ tipoCompraId: e.currentTarget.value })
                              }}
                              disabled={!this.state.editarForm}
                            >
                              <option
                                key={0}
                                value={0}
                              >--Seleccionar Tipo de Compra--</option>
                              {this.props.tiposCompras && (this.props.tiposCompras.map((tipoCompra) => (
                                <option
                                  key={tipoCompra.tipoCompraId}
                                  value={tipoCompra.tipoCompraId}
                                >
                                  {tipoCompra.nombre}
                                </option>
                              )))}
                            </select>
                          </div>

                          <div className="form-group mt-4">
                            <label htmlFor="estatusSecundario">Estatus secundario</label>
                            <select
                              id="estado"
                              className="form-control"
                              ref={this.estadoRef}
                              defaultValue={estado ? estado.estadoId : 0}
                              disabled={!this.state.editarForm}
                              onChange={(e) => {
                                // console.log("estado",e.currentTarget.value)
                                this.setState({ estadoId: e.currentTarget.value })
                              }}
                            >
                              <option
                                key={0}
                                value={""}
                              >
                                {"--Seleccionar Estatus"}
                              </option>
                              {this.props.estados && (this.props.estados.map((estado) => (
                                <option
                                  key={estado.estadoId}
                                  value={estado.estadoId}
                                >
                                  {estado.nombre}
                                </option>
                              )))}
                            </select>
                          </div>

                        </div>
                        <div className="col-6 pb-4">
                          <h3>-</h3>
                          <hr style={{ marginTop: '0rem' }} />

                          <div className="form-group mt-4">
                            <label className="text-left" htmlFor="numeroSigef">Número De SIGEF</label>
                            <input
                              type="number"
                              className="form-control text-dark"
                              id="numeroSigef"
                              ref={this.sigefRef}
                              defaultValue={sigef}
                              disabled={!this.state.editarForm}
                              onChange={(e) => {
                                // console.log("sigef",e.currentTarget.value)
                                this.setState({ sigef: e.currentTarget.value })
                              }}
                            />
                          </div>


                          <div className="custom-control custom-checkbox mt-4">
                            <input type="checkbox" className="custom-control-input" id="procesoPlurianual" disabled={!this.state.editarForm} ref={this.esProcesoPlurianual} />
                            <label className="custom-control-label" htmlFor="procesoPlurianual">¿Es proceso plurianual?</label>
                          </div>

                          <div className="form-group col-md-12">
                            <label>Actualizar especificaciones técnicas(PDF)</label>
                            <input
                              id="inputpdf"
                              className="form-control text-dark"
                              type="file" accept=".pdf"
                              name="file[]"
                              onChange={
                                (e) => {
                                  e.preventDefault();
                                  ////////////////////////////////////////////////////////////
                                  var fileInput = document.getElementById('inputpdf');
                                  var filePath = fileInput.value;
                                  var allowedExtensions = /(\.pdf|\.PDF)$/i;
                                  if (!allowedExtensions.exec(filePath)) {
                                    swal("Error de tipo de archivo", "Debe cargar archivos en formato PDF", "error");
                                    fileInput.value = '';
                                    return false;
                                  }
                                  ///////////////////////////////////////////////
                                   this.cargarEspecificacionesTecnicas(e);
                                }
                              }
                            />
                          </div>

                        </div>
                      </div>
                      <div className="text-center">
                        <div>
                          <label style={{ fontWeight: "bold", fontSize: 20 }}>Artículos</label>
                        </div>
                        <Table
                          responsive
                          className="table-striped">
                          <thead>
                            <tr>
                              <th>Producto</th>
                              <th>Subclase</th>
                              <th>Cod. Gasto</th>
                              <th>Valor</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {actividadesArticuloSeguimiento && actividadesArticuloSeguimiento.length > 0 && (actividadesArticuloSeguimiento.map(
                              (articulo) =>
                                articulo != null &&
                                articulo != undefined && (
                                  
                                  <Insumos
                                    key={articulo.articuloId}
                                    eliminarPresupuesto={this.eliminarPresupuesto}
                                    ActividadArticuloSeguimiento={articulo}
                                    ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                                    user={this.props.user}
                                    editar={this.validarButtonEliminar}
                                  />
                                )
                            )
                         
                            )}
                               <tr>
                               <td className="text-left font-weight-bold">Total</td>
                               <td></td>
                               <td></td>
                               <td className="font-weight-bold">{this.CalcularTotalInsumos(actividadesArticuloSeguimiento)}</td>
                               <td></td>
                               </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="row pb-3 pt-5">
                        <div className="col-4 m-auto">
                          <button
                            onClick={() => this.irAtras()}
                            className="btn btn-danger"
                          >
                            CANCELAR
                          </button>
                          <button
                            onClick={(e) => this.actualizarRequerimiento(e)}
                            className="btn btn-primary"
                            type="submit"
                          >
                            ACTUALIZAR
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {this.state.modalDetalleArticulo && (
                <ModalDetalleArticulo
                  presupuesto={this.state.presupuesto}
                  ActivarModalDetalleArticulo={this.ActivarModalDetalleArticulo}
                  modalDetalleArticulo={this.state.modalDetalleArticulo}

                ></ModalDetalleArticulo>
              )}
            </div>
          </React.Fragment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  poaActivo: state.poas.poaActivo,
  user: state.auth.signed,
  tiposCompras: state.tiposCompras.tiposCompras,
  estados: state.estados.estados,
  usuarios: state.usuarios.usuarios,
  requerimiento: state.requerimientos.requerimiento,
  isLoading: state.requerimientos.isLoading,
  usuariosAnalistasAsignados: state.usuarios.usuariosAnalistasAsignados,
});

const mapDispatchToProps = (dispatch) => ({
  getListaTipoCompras: () => dispatch(getListaTipoCompras()),
  getListaEstados: () => dispatch(getListaEstados()),
  getListaUsuarios: () => {
    dispatch(getListaUsuarios());
  },
  updateRequerimientos: requerimiento => dispatch(updateRequerimientos(requerimiento)),
  getRequerimiento: requerimientoId => dispatch(getRequerimiento(requerimientoId)),
  getListaAnalistasAsignados: () => dispatch(getListaAnalistasAsignados()),
  deletePresupuesto: (presupuestoId) => dispatch(deletePresupuesto(presupuestoId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Authorization(EditarRequerimiento, [
    "Administrador general",
    "Responsables de compras"
  ])
);

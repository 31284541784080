import {
    MOSTRAR_ACTIVIDADES,
    MOSTRAR_ACTIVIDAD,
    ACTIVIDAD_SUCCEEDED,
    LOADING_ACTIVIDAD,
    CREAR_ACTIVIDADES,
    EDITAR_ACTIVIDADES,
    ELIMINAR_ACTIVIDADES,
    MOSTRAR_ACTIVIDAD_LOCAL,
    ELIMINAR_PRESUPUESTO,
    ADD_PRESUPUESTO,
    TOTAL_PONDERACION_AREA_ACTIVIDADES,
    ELIMINAR_MONITOREO_ACTIVIDAD,
    CREAR_MONITOREO_ACTIVIDAD,
    MONITOREO_MEDIOS_VERIFICACION,
    MONITOREO_UPDATE_APROBACION_ACTIVIDADES,
    MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR,
    MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES,
    CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO,
    ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO
} from "./../types";
import initialState from "./../initial-state";

const { actividades } = initialState;

const actividadesReducer = (state = actividades, action) => {
    switch (action.type) {
        case LOADING_ACTIVIDAD:
            return {
                ...state,
                isLoading: true
            };
        case ACTIVIDAD_SUCCEEDED:
            return {
                ...state,
                isLoading: false
            };
        case ELIMINAR_MONITOREO_ACTIVIDAD:
        case MOSTRAR_ACTIVIDAD:
            return {...state, actividad: action.payload };
        case MOSTRAR_ACTIVIDADES:
            return {
                ...state,
                actividades: action.payload
            };
        case CREAR_ACTIVIDADES:
            return {
                ...state,
                actividades: [...state.actividades, action.payload]
            };
        case CREAR_MONITOREO_ACTIVIDAD:
            return {
                ...state,
                monitoreo: [...state, action.payload]
            };
        case EDITAR_ACTIVIDADES:
            return {
                ...state,
                actividades: state.actividades.map(actividad =>
                    actividad.actividadId === action.payload.actividadId ?
                    (actividad = action.payload) :
                    actividad
                )
            };
        case ELIMINAR_ACTIVIDADES:
            return {
                ...state,
                actividades: [
                    ...state.actividades.filter(
                        actividad => actividad.actividadId !== action.payload
                    )
                ]
            };
        case MOSTRAR_ACTIVIDAD_LOCAL:
            return {
                ...state,
                actividad: state.actividades.find(
                    actividad => actividad.actividadId === action.payload
                )
            };
        case ELIMINAR_PRESUPUESTO:
            return {
                ...state,
                presupuesto: [
                    ...state.actividades.actividad.presupuesto.filter(
                        insumo => insumo.presupuestoId !== action.payload
                    )
                ]
            };
        case ADD_PRESUPUESTO:
            return {
                ...state,
                presupuesto: [
                    ...state.actividades.actividad.presupuesto,
                    action.payload
                ]
            };
        case TOTAL_PONDERACION_AREA_ACTIVIDADES:
            return {
                ...state,
                totalPonderacionActividades: action.payload
            };
        case MONITOREO_MEDIOS_VERIFICACION:
            return {
                ...state,
                actividad: state.actividades.actividad.monitoreo.map(monitoreo =>
                    monitoreo.monitoreoId === action.payload.monitoreoId && [...monitoreo.medioVerificacion, action.payload])
            }
        case MONITOREO_UPDATE_APROBACION_ACTIVIDADES:
         return {
                    ...state,
                    actividad: {...state.actividad,monitoreo: state.actividad.monitoreo.map(monitoreo =>(
                        monitoreo.monitoreoId === action.payload.monitoreoId ? (monitoreo= action.payload):monitoreo))}
                }
        case MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDOR:
            return {...state, actividadesArticuloSeguimientoPorProveedor: action.payload };
        case MOSTRAR_CANTIDAD_ITEMS_POR_PROVEEDORES:
            return {...state, actividadesArticuloSeguimientoPorProveedorCantidad: action.payload };
        case CREAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO:
            return {
                ...state,
                actividadesArticuloSeguimiento: [...state, action.payload]
            };
        case ELIMINAR_ACTIVIDADES_ARTICULOS_SEGUIMIENTO:
            return {
                ...state,
                actividadesArticuloSeguimiento: [...state, action.payload]
            };
        default:
            return state;
    }
};

export default actividadesReducer;